import React, {useState, useEffect, useRef, useContext} from "react";
import {Button, message, Radio} from 'antd'
import Swiper from 'react-id-swiper';
import VideoJS from "../../components/ShowVideo";
import '../StageAnalysisPage/index.css'
import {store} from "../../../store";
import api, {errorInfo} from "../../../api/api";
import MatchInfo from "../../../data_structure/MatchInfo";
import TouchOverlay from '../VideoPage/Dragtime';
import videojs from "video.js";

const WholdRallyVideo = (props) => {


    const {state,dispatch}=useContext(store)
    const videoUrl = api.getVideoUrl(state.match_id)
    const players = state.matchInfo.players
    const activeIndex = props.activeIndex
    const playerRef = useRef(null);
    const [pointButtons, setPointButtons] = useState(null)
    const [pointDatas,setPointDatas] = useState(null)
    const [currentTime, setCurrentTime] = useState(0)
    const [pauseTime, setPauseTime] = useState(0)
    const [ifPause, setIfPause] = useState(true)
    const [gameIndex,setGameIndex]=useState(0)
    const Component = videojs.getComponent("Component");

    const swiperParams = {
        slidesPerView: 1,
        spaceBetween: 30,
        mousewheel: true,
        simulateTouch: true,
    }
    useEffect(() => {
        if (activeIndex === 2) {
            setIfPause(false)
        } else {
            setIfPause(true)
        }
    },[activeIndex])
    const videoJsOptions = {
        autoplay: false,
        controls: true,
        bigPlayButton: true,
        responsive: true,
        fluid: true,
        preload: 'auto',
        language: 'zh-CN', // 设置语言
        controlBar: {
            children: [
                { name: 'playToggle' },
                { name: 'currentTimeDisplay' }, // 当前已播放时间
                { name: 'progressControl' },
                { name: 'durationDisplay' }, // 总时间
                {
                    name: 'volumePanel', // 音量控制
                    inline:false
                },
                { name: 'FullscreenToggle' }, // 全屏
            ],
        },
        sources: [{
            src: videoUrl,
            type: 'video/mp4'
        }]
    }


    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });

        Component.registerComponent("TouchOverlay", TouchOverlay);
        const touchOptions = {};
        const properIndex = player.children().indexOf(player.getChild("BigPlayButton"));
        player.addChild("TouchOverlay", touchOptions, properIndex);
    };


    useEffect(() => {
        console.log("game index is ",gameIndex)
        if (!pointDatas) {
            setPointDatas(state.gameDetail[gameIndex])
        } else {
            setPointDatas(state.gameDetail[gameIndex])
            let n = state.gameDetail[gameIndex].list.length
            let cur = [], total = []
            for (let i = 0; i < n; i++) {
                // if (cur.length < 15) {
                //     cur.push(state.gameDetail[gameIndex].list[i])
                // } else {
                //     total.push(cur)
                //     cur = [state.gameDetail[gameIndex].list[i]]
                // }
                cur.push(state.gameDetail[gameIndex].list[i])
            }
            total.push(cur)
            let buttons = total.map((group,idx) => {
                return (
                    <div className="point-button-container" key={idx}>
                        {group.map((item, index) => {
                            return (
                                <Button className="point-button" key={index} onClick={() => {
                                    setCurrentTime(item.startTime)
                                    setPauseTime(item.endTime)
                                }}>
                                    {/* serveSide 是发球方 */}
                                    <div className="dot-1" style={{visibility: item.winSide===0 ? 'visible' :'hidden'}}></div>
                                    <div className="score-1">{item.score[0]} </div>
                                    <div className="rally-score">{ ' ' }:{ ' ' }</div>
                                    <div className="score-2">{item.score[1]} </div>
                                    <div className="dot-2" style={{visibility: item.winSide===1 ? 'visible' :'hidden'}}></div>
                                </Button>)
                        })}
                    </div>
                )
            })
            setPointButtons(buttons)
        }
    }, [pointDatas, gameIndex, state.gameDetail])


    let gameButtons  = state.gameDetail.map((item, index) => {
        return (
            <Radio.Button value={index+''} id={"game-"+item.index} className="rally-button" key={index} onClick={() => { setGameIndex(index) }}>
                第{index+1}局
            </Radio.Button>)
    })

    return (

        <div className="video-container">
            {state.matchInfo.has_video ? <div className="video-player">
                <VideoJS
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                    currentTime={currentTime}
                    pauseTime={pauseTime}
                    ifPause={ifPause} isFull={true}/>
            </div> : null}
            <div className="video-banner">
                <div className="banner-player">
                        <span className="player-name">
                            {(players[0]).length > 1 ? <div>
                                    <span>{players[0][0] + ' '}</span>
                                    <span>{players[0][1]}</span></div>
                                : players[0]
                            }
                        </span>
                    <span className="vs">vs</span>
                    <span className="player-name">
                            {(players[1]).length > 1 ? <div>
                                    <span>{players[1][0] + ' '}</span>
                                    <span>{players[1][1]}</span></div>
                                : players[1]
                            }
                        </span>
                </div>
                <div className={'video-divider'}>
                    <div className={'vs-divider'} style={{backgroundColor: '#587ec4', marginBottom: 5}}></div>
                    <div className={'vs-result'}>
                        {state.matchInfo.has_data && pointDatas ? pointDatas.score[0] + '-' + pointDatas.score[1] : null}
                        {/*{state.matchInfo.has_data?state.gameResult[0]+'-'+state.gameResult[1]: null}*/}
                    </div>
                    <div className={'vs-divider'} style={{backgroundColor: '#fbb03b', marginBottom: 5}}></div>
                </div>
            </div>
            <div style={{marginTop: 10}}>
                <Radio.Group className="video-rally-group" defaultValue={'0'}>
                    {gameButtons}
                </Radio.Group>
            </div>
            <div
                style={{width: '100%', height: '3px', backgroundColor: '#0b286b', marginTop: 5, marginBottom: 5}}></div>
            <div className="video-point-group">
                {/*<Swiper {...swiperParams} shouldSwiperUpdate>*/}
                {pointButtons}
                {/*</Swiper>*/}
            </div>
            <div style={{height: 20}}></div>
            {/*<div>*/}
            {/*    {state.gameDetail[gameIndex].list.map((item,gameIndex)=>{*/}
            {/*        return <td>{' '+item.score[0]+'-'+item.score[1]+' ; '}</td>*/}
            {/*    })}*/}
            {/*</div>*/}
        </div>

    )
}

export default WholdRallyVideo