export default function errorInfo(errCode) {
    switch (errCode) {
        case 0:
            return "LackInfo";

        case 100:
            return "UserNotFound";
        case 101:
            return "UserLackPermission";
        case 102:
            return "DuplicateUser";
        case 103:
            return "WeakPassword";
        case 104:
            return "CannotUseRootPermission";

        case 200:
            return "DuplicateMatch";
        case 201:
            return "MatchNotFound";
        case 202:
            return "WrongDate";
        case 203:
            return "WrongLocation";
        case 204:
            return "WrongType";
        case 205:
            return "WrongCompetition";
        case 206:
            return "WrongRound";
        case 207:
            return "WrongPlayers";
        case 208:
            return "WrongMatchName";
        case 209:
            return "CannotChangePlayersNumber";

        case 300:
            return "WrongOperator";
        case 301:
            return "WrongOperand";

        case 400:
            return "DataNotFound";
        case 401:
            return "DataNotCreate";
        case 402:
            return "WrongDataType";
        case 403:
            return "NothingUndo";
        case 404:
            return "InvalidData";
        case 500:
            return "DuplicatePlayer";
        case 501:
            return "PlayerNotFound";

        default:
            return errCode;
    }
}
