import React, {useState, useEffect, useRef, useContext} from "react";
import {Button, message, Radio} from 'antd'
import Swiper from 'react-id-swiper';
import VideoJS from "../../components/ShowVideo";
import '../StageAnalysisPage/index.css'
import {store} from "../../../store";
import api, {errorInfo} from "../../../api/api";
import WholeRallyVideo from "./WholeRallyVideo";
import tableTennisIcon from "../../../media/tt-white.png";

/**
 * WholeGame is used to show the whole game including {game0}{game1}{game2}... with video and the detail rally info
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RallyInfoPage = (props) => {


    const {state,dispatch}=useContext(store)
    const videoUrl = api.getVideoUrl(state.match_id)
    const [players, setPlayers] = useState(state.matchInfo.players)
    const playerRef = useRef(null);
    const activeIndex = props.activeIndex
    const [ifPause, setIfPause] = useState(true)
    const [ifContinous, setIfContinous] = useState(false)
    const [detailTimeTable,setDetailTimeTable] = useState([])
    const setPlayerById = ()=>{
        setPlayers(state?state.matchInfo.players:[['-'],['-']])
        setDetailTimeTable(state?state.gameDetail:[]);
    }
    useEffect(()=>setPlayerById(),[])



    useEffect(() => {
        if (props.activeIndex === 3) {
            setIfPause(false)
        } else {
            setIfPause(true)
        }
    },[props.activeIndex])



    return (
        <div className="mobile-page">
            <div className="mobile-subtitle">
                <img src={tableTennisIcon} width={26} style={{position:'absolute',left:2,top:2}}/>
                <div>各回合信息</div>
            </div>
            <WholeRallyVideo video={detailTimeTable}  activeIndex={activeIndex} />

        </div>
    )
}

export default RallyInfoPage;