// import cookie from "react-cookies";
import {login} from "./api";

const DEBUG = false;
const LOCAL = false;

const nodeList = {
    // 'germany': {label: '德国', address: 'https://olyttbackend.sportvis.cn/v1/',ip:'43.157.67.42'},
    // 'hangzhou1': {label: '杭州', address: 'http://47.97.66.50:5000/v1/',ip:'47.97.66.50'},
    'nanjing1': {label: '南京', address: 'https://ttbackend.sportvis.cn/v1/',ip:'119.45.201.191'},
    // 'local':{label: '本地', address: 'http://127.0.0.1:5000/v1/',ip:'127.0.0.1'},
    // 'pc417':{label: '417-pc', address: 'http://10.76.2.57:5000/v1',ip:'10.76.2.57'},
}




// const hostname = `${LOCAL ? "10.76.2.57" : "47.97.66.50"}:5000`
// const hostname = 'localhost:3000'




export {DEBUG,LOCAL,nodeList};

