import React from 'react';
import { Layout, Tabs } from "antd";
// import "./index.css";
import Match from "../match";
import AddMatch from "../match/add";
import MyHeader from "../../components/MyHeader";
import MyTail from "../../components/MyTail";
import "./../match/index.css"
const { Header,  Content } = Layout;
const { TabPane } = Tabs;


export default function Homepage (){
        return (
            <Layout className={"bg-image"} style={{position: 'relative', minHeight:document.documentElement.clientHeight}}>
                <div  >
                    <MyHeader/>
                    <Content className="pageWrapper">
                        <Tabs  defaultActiveKey='1'
                              // tabBarStyle={{widths:'45%',color:'red'}}
                              tabPosition={'bottom'} centered >
                            <TabPane  key='1' tab="比赛列表"  >
                                <Match />
                            </TabPane>
                            <TabPane  key='2' tab="数据上传">
                                <AddMatch/>
                            </TabPane>
                        </Tabs>
                    </Content>
                    {/*<div className='bottomTip'>*/}
                    {/*    <div className='logo'></div>*/}
                    {/*     */}
                    {/*</div>*/}
                    <MyTail />
                </div>
            </Layout >

        )

}