import videojs from 'video.js';
const { location } = window;
window.addEventListener('load', () => {
    const pageLoadId = Math.random().toString(36).substr(2, 9);
    sessionStorage.setItem('pageLoadId', pageLoadId);
});
class CustomDownloadButton extends videojs.getComponent('Button') {
    constructor(player, options) {
        super(player, options);
        this.controlText('下载视频');
        player.on('filenameSet', (event, filename) => {
            this.filename = filename;
        });
        this.downloading = false;
    };

    handleClick() {
        // 检查是否已经触发过下载
        if (this.downloading) {
            // 如果已经下载过，则弹出警告框
            console.log('has down pass check')
            alert('视频已下载，不能重复下载！');
        }
        else{
            // 触发下载的逻辑
            const videoUrl = this.player_.currentSrc(); // 获取当前视频的 URL
            this.downloadVideo(videoUrl);
        }
    }
    downloadVideo(videoUrl) {
        const filename = this.filename;
        console.log("filename:"+filename)
        const progressBar = document.getElementById('downloadProgress');
        const progressStyle = progressBar.style;
        const progressText = document.getElementById('progressText');
        const progressTextStyle = progressText.style;
        progressStyle.display = 'block';
        progressTextStyle.display = 'block';

        const xhr = new XMLHttpRequest();
        xhr.open('GET', videoUrl, true);
        xhr.responseType = 'blob';

        xhr.onprogress = function(event) {
            const progressBar = document.getElementById('downloadProgress');
            if (event.lengthComputable && progressBar) {
                this.downloading = true;
                const progressText = document.getElementById('progressText');
                const progressTextStyle = progressText.style;
                const progressStyle = progressBar.style;
                progressStyle.display = 'block';
                progressTextStyle.display = 'block';
                const percent = (event.loaded / event.total) * 100;
                sessionStorage.setItem('videoDownloadedPercent', `${percent}`);

                progressBar.value = percent; // 对于<progress>元素
                progressText.textContent = `下载进度: ${percent.toFixed(0)}%`;
                if (percent >= 100) {
                    progressStyle.display = 'none';
                    progressTextStyle.display = 'none';
                }
            }
        };

        xhr.onload = function() {
            // 下载完成的处理
            if (xhr.status === 200) {
                // 处理视频Blob，例如创建下载链接
                const blob = new Blob([xhr.response], { type: 'video/mp4' });
                // 创建一个对象URL
                const objectUrl = URL.createObjectURL(blob);
                // 创建一个<a>元素
                const link = document.createElement('a');
                link.href = objectUrl;
                // 使用this.filename作为下载的文件名
                link.download = filename || 'default.mp4'; // 如果没有filename，使用默认文件名
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(objectUrl);
            } else {
                console.error('下载失败:', xhr.status);
            }
        };

        xhr.onerror = function() {
            console.error('下载出错');
        };

        xhr.send();
    }

    createEl() {
        const el = super.createEl('button', {
            className: 'vjs-custom-download-button',
            innerHTML: `<span ><?xml version="1.0" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16" height="14"><path d="M877.49 381.468H668.638V68.191H355.36v313.277H146.51l365.489 365.49 365.49-365.49zM146.51 851.383v104.425h730.98V851.383H146.51z" fill="#ffffff"></path></svg></span>`
        });

        el.addEventListener('click', ()=>this.handleClick);

        return el;
    }
}

videojs.registerComponent('CustomDownloadButton', CustomDownloadButton);
// Download.js
export default CustomDownloadButton;
