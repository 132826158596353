import React, {useContext, useEffect, useState} from "react";
import './index.css'
import {Button, message} from "antd";
import api, {errorInfo} from "../../../api/api";
import {Link} from "react-router-dom";
import {store} from "../../../store";


function MultiTable(props) {
    const swiper=props.swiper
    const {state,dispatch}=useContext(store)
    const [team,setTeam]=useState('team1');
    const score_part_name = ["发球/第三拍","接发球/第四拍","相持段"]
    const tableMatrix=[
        {"no":0,"name_first_col":score_part_name[0],"players":[0,1],"row":[0,1]},
        {"no":1,"name_first_col":score_part_name[1],"players":[1,0],"row":[2,3]},
        {"no":2,"name_first_col":score_part_name[0],"players":[1,0],"row":[4,5]},
        {"no":3,"name_first_col":score_part_name[1],"players":[0,1],"row":[6,7]}
        ]
    function calCount(twoDArray){
        let count=0;
        if(twoDArray){
            for(let i=0;i<twoDArray.length;i++){
                if(twoDArray[i]){
                    count+=twoDArray[i].length;
                }
            }
        }
        return count;
    }
    /**
     * @param {string} team1orteam2: team1 or team2
     * @param {array} enum_of_row: row0&row1 row2&row3 row4&row5 row6&row7
     * @param {number} idx_of_col:index of column
     */
    function calSum(team1orteam2,enum_of_row,idx_of_col){
        let count = 0 ;
        for(let i=0;i<enum_of_row.length;i++){
            count+=calCount(props.data[team1orteam2][enum_of_row[i]][idx_of_col])
        }
        return count;
    }
    return (
        <div className="detail-table">
            <div style={{marginTop:'2px'}}>
                <Button style={{backgroundColor:team==='team1'?'#0b286b':'#fff',color:team==='team1'?'#fff':'#0b286b'}}  onClick={()=>setTeam('team1')}>pair1</Button>
                <Button style={{backgroundColor:team==='team2'?'#0b286b':'#fff',color:team==='team2'?'#fff':'#0b286b'}}  onClick={()=>setTeam('team2')}>pair2</Button>
            </div>
            <div style={{marginBottom:'5px'}} >
                {tableMatrix.map((item)=>{
                    return <div className={'detail-table-container-'+(item.no%2)}>
                        <table>
                        <thead>
                        <tr>
                            <th className="name" rowSpan="2" colSpan="1" ></th>
                            <th rowSpan="1" colSpan="2">{item.name_first_col}</th>
                            <th rowSpan="1" colSpan="2" >相持段</th>
                            <th rowSpan="1" colSpan="2" >总计</th>
                        </tr>
                        <tr className="second-head" >
                            {[0,1,0,1,0,1].map(r=><th>{r===0?'得分':"失分"}</th>)}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th className="row-head">{props.players[(team==='team2'?1:0)][item.players[0]]}</th>
                            {props.data[team]?props.data[team][item.row[0]].map((it, index) => {return (
                                <td key={index} onClick={()=>{
                                    dispatch({
                                    type:'setSelectDetail',
                                    payload:{
                                        "m_id":props.match_id,
                                        "table_idx":item.no,
                                        "col_idx":index,
                                        "row_idx":0,
                                        "team_idx":team==='team1'?0:1,
                                        "list_content":it
                                    }
                                })
                                    props.setIdx(3)
                                }
                                }>
                                    {/*<Link to={`/score_video/${props.match_id}/${item.no}/${index}`}  >*/}
                                    {calCount(it)}
                                    {/*</Link>*/}
                                </td>
                            )}): null}
                            {/*//这一行 第一列 + 第三列*/}
                            <td key={4}
                                onClick={()=>{dispatch({
                                    type:'setSelectDetail',
                                    payload:{
                                        "m_id":props.match_id,
                                        "table_idx":item.no,
                                        "col_idx":4,
                                        "row_idx":0,
                                        "team_idx":team==='team1'?0:1,
                                        // "list_content":props.data[team]?props.data[team][item.row[0]][0].concat(props.data[team][item.row[0]][2]):[]
                                        "list_content":props.data[team]?props.data[team][item.row[0]][0].map((col_0,idx)=>
                                            col_0.concat(props.data[team][item.row[0]][2][idx])
                                        ):[]
                                    }
                                });props.setIdx(3)}}
                            >
                                {/*<Link to={`/score_video/${props.match_id}/${item.no}/4`} >*/}
                                    {props.data[team]?(calCount(props.data[team][item.row[0]][0])+calCount(props.data[team][item.row[0]][2])) :null}
                                {/*</Link>*/}
                            </td>
                            <td key={5} onClick={()=>{dispatch({
                                type:'setSelectDetail',
                                payload:{
                                    "m_id":props.match_id,
                                    "table_idx":item.no,
                                    "col_idx":5,
                                    "row_idx":0,
                                    "team_idx":team==='team1'?0:1,
                                    // "list_content":props.data[team]?props.data[team][item.row[0]][1].concat(props.data[team][item.row[0]][3]):[]
                                    "list_content":props.data[team]?props.data[team][item.row[0]][1].map((col_0,idx)=>
                                        col_0.concat(props.data[team][item.row[0]][3][idx])
                                    ):[]
                                }
                            });props.setIdx(3)}}
                            >
                                {/*<Link to={`/score_video/${props.match_id}/${item.no}/5`} >*/}
                                    {props.data[team]?(calCount(props.data[team][item.row[0]][1])+calCount(props.data[team][item.row[0]][3])):null }
                                {/*</Link>*/}
                            </td>
                        </tr>
                        <tr>
                            <th className="row-head">{props.players[(team==='team2'?1:0)][item.players[1]]}</th>
                            {props.data[team]?props.data[team][item.row[1]].map((it, index) => {
                                return (<td key={index}
                                            onClick={()=>{dispatch({
                                                    type:'setSelectDetail',
                                                    payload:{
                                                        "m_id":props.match_id,
                                                        "table_idx":item.no,
                                                        "col_idx":index,
                                                        "row_idx":1,
                                                        "team_idx":team==='team1'?0:1,
                                                        "list_content":it
                                                    }
                                                });
                                                props.setIdx(3)
                                            }
                                }>
                                {/*<Link to={`/score_video/${props.match_id}/${item.no}/${index}`}  >*/}
                                    {calCount(it)}
                                {/*</Link>*/}
                            </td>)}): null}
                            {/*//这一行 第一列 + 第三列*/}
                            <td key={4} onClick={()=>{
                                dispatch({
                                type:'setSelectDetail',
                                payload:{
                                    "m_id":props.match_id,
                                    "table_idx":item.no,
                                    "col_idx":4,
                                    "row_idx":1,
                                    "team_idx":team==='team1'?0:1,
                                    // "list_content":props.data[team]?props.data[team][item.row[1]][0].concat(props.data[team][item.row[1]][2]):[]
                                    "list_content":props.data[team]?props.data[team][item.row[1]][0].map((col_0,idx)=>
                                        col_0.concat(props.data[team][item.row[1]][2][idx])
                                    ):[]
                                }
                            });props.setIdx(3)}}>
                                {/*<Link to={`/score_video/${props.match_id}/${item.no}/4`} >*/}
                                {props.data[team]?(calCount(props.data[team][item.row[1]][0])+calCount(props.data[team][item.row[1]][2])) :null}
                                {/*</Link>*/}
                            </td>
                            <td key={5} onClick={()=>{dispatch({
                                type:'setSelectDetail',
                                payload:{
                                    "m_id":props.match_id,
                                    "table_idx":item.no,
                                    "col_idx":5,
                                    "row_idx":1,
                                    "team_idx":team==='team1'?0:1,
                                    // "list_content":props.data[team]?props.data[team][item.row[1]][1].concat(props.data[team][item.row[1]][3]):[]
                                    "list_content":props.data[team]?props.data[team][item.row[1]][1].map((col_0,idx)=>
                                        col_0.concat(props.data[team][item.row[1]][3][idx])
                                    ):[]
                                }
                            });props.setIdx(3)}}>
                                {/*<Link to={`/score_video/${props.match_id}/${item.no}/5`} >*/}
                                    {props.data[team]?(calCount(props.data[team][item.row[1]][1])+calCount(props.data[team][item.row[1]][3])):null }
                                {/*</Link>*/}
                            </td>
                        </tr>
                        <tr>
                            <th className="row-head">合计</th>
                            {[0,1,2,3].map(idx_col=>(<td key={idx_col+'-2'}
                                                         onClick={()=>{}}
                                // onClick={()=>{dispatch({
                                //     type:'setSelectDetail',
                                //     payload:{
                                //         "m_id":props.match_id,
                                //         "table_idx":item.no,
                                //         "col_idx":idx_col,
                                //         "row_idx":2,
                                //         "team_idx":team==='team1'?0:1,
                                //         "list_content":props.data[team]?props.data[team][item.row[0]][idx_col].concat(props.data[team][item.row[1]][idx_col]):[]
                                //     }
                                // });props.setIdx(3)}}
                            >

                                    {props.data[team]? calSum(team,item.row,idx_col) :null}

                            </td>))}
                            <td key={"4-2"}
                                onClick={()=>{}}
                                // onClick={()=>{dispatch({
                                //     type:'setSelectDetail',
                                //     payload:{
                                //         "m_id":props.match_id,
                                //         "table_idx":item.no,
                                //         "col_idx":4,
                                //         "row_idx":2,
                                //         "team_idx":team==='team1'?0:1,
                                //         "list_content":props.data[team]?props.data[team][item.row[0]][4].concat(props.data[team][item.row[1]][4]):[]
                                //     }
                                // });props.setIdx(3)}}
                            >
                                {props.data[team]?(calCount(props.data[team][item.row[0]][0])+calCount(props.data[team][item.row[0]][2])+calCount(props.data[team][item.row[1]][0])+calCount(props.data[team][item.row[1]][2])):null }

                            </td>
                            <td
                                key={"5-2"}
                                onClick={()=>{}}
                            //     onClick={()=>{dispatch({
                            //     type:'setSelectDetail',
                            //     payload:{
                            //         "m_id":props.match_id,
                            //         "table_idx":item.no,
                            //         "col_idx":5,
                            //         "row_idx":2,
                            //         "team_idx":team==='team1'?0:1,
                            //         "list_content":props.data[team]?props.data[team][item.row[0]][5].concat(props.data[team][item.row[1]][5]):[]
                            //     }
                            // });props.setIdx(3)}}
                            >

                                {props.data[team]?(calCount(props.data[team][item.row[0]][1])+calCount(props.data[team][item.row[0]][3])+calCount(props.data[team][item.row[1]][1])+calCount(props.data[team][item.row[1]][3])):null}

                            </td>
                        </tr>
                        </tbody>
                        </table></div>
                })}

            </div>
            <div style={{height:30}}>

            </div>
        </div>
    )
}

export default MultiTable;

