import React,{useEffect,useRef, useState} from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import 'videojs-landscape-fullscreen'
import './../../css/video-js.css'
//Guide : https://videojs.com/guides/react/

/** 判断手机机型 **/
function isAndroidOrIOS() {
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        return "android"
    }
    if (isiOS) {
        return "ios"
    }
    return false
}

export const VideoJS = ( props ) => {

    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const { options, onReady, currentTime, pauseTime, ifPause, isFull,ifContinous,pointDatas,pointIdx,setPointIdx} = props;
    const Component = videojs.getComponent("Component");
    useEffect(() => {
        // make sure Video.js player is only initialized once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;
            const player = playerRef.current = videojs(videoElement, options, () => {
                console.log("player is ready");

                if (true || isAndroidOrIOS() !== 'ios') {
                    player.landscapeFullscreen({
                        fullscreen: {
                            enterOnRotate: true,
                            alwaysInLandscapeMode: true
                        }
                    });
                }
                player.playsinline(true)
                onReady && onReady(player);
            });
        } else {
            // you can update player here

        }
    }, [options, videoRef]);

    useEffect(() => {
        if (playerRef.current) {
            const player = playerRef.current
            if (ifPause) {
                player.pause()
            } else if (isFull && pauseTime > currentTime) {
                let curTime = currentTime > 3 ? currentTime - 3 : 0
                player.currentTime(curTime);
                player.play();
            }  else if (!ifContinous && pauseTime > currentTime) {
                let curTime = currentTime > 3 ? currentTime - 3 : 0
                let pauTime = (pauseTime+3) < player.duration() ? (pauseTime+3) : player.duration()

                const handleBar = function () {
                    if (player.currentTime() >= pauTime) {
                        player.pause();
                        player.off('timeupdate', handleBar)
                    }
                };
                player.on('timeupdate', handleBar);
                player.currentTime(curTime);
                player.play();

                player.on('mousemove', () => { player.off('timeupdate', handleBar) })

            }
        }
    }, [currentTime, pauseTime, ifPause, isFull, ifContinous])


    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    function autoplay(start,player) {
        let startTime = pointDatas[start].startTime, endTime = pointDatas[start].endTime
        let curTime = startTime > 3 ? startTime - 3 : 0
        let pauTime = (endTime + 5) < player.duration() ? (endTime + 5) : player.duration()
        const handleBar = function () {
            if (player.currentTime() >= pauTime) {
                if (start + 1 >= pointDatas.length) {
                    setPointIdx(0)
                } else {
                    setPointIdx(start+1)
                }
                player.off('timeupdate', handleBar)
            }
        };
        player.on('timeupdate', handleBar);
        player.currentTime(curTime);
        player.play();

        player.on('mousemove', () => { player.off('timeupdate', handleBar) })
    }


    useEffect(() => {
        if (playerRef.current && ifContinous && !ifPause && currentTime < pauseTime) {
            const player = playerRef.current
            let len = pointDatas.length
            if (pointIdx < len) {
                autoplay(pointIdx, player)
            }
        }
    },[pointIdx, ifContinous, ifPause, currentTime, pauseTime])


    return (
        <div data-vjs-player>
            <video ref={videoRef} className="video-js vjs-big-play-centered"  />
        </div>
    );
}

export default VideoJS;
