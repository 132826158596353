import React, {Component, useContext, useEffect, useState} from "react";
import './index.css'
import DetailTable from "../../components/DetailTable";
import {store} from "../../../store";
import api, {errorInfo} from "../../../api/api";
import {message} from "antd";
import "../StageAnalysisPage/index.css"

const GameScorePage = (props) => {
    // let { data, setScoreData, swiper } = props
    // let table1 = data.table[0], table2 = data.table[1]
    const {state, dispatch} = useContext(store);
    const [gameScore,setGameScore]=useState([]);
    const [noScoreTd,setScoreTd] = useState([])
    const share_match_id=window.location.href.split('/').reverse()[1]==='game'?parseInt(window.location.href.split('/').reverse()[0]):-2;//-2 means not shared

    const getGameScore = async () => {
        try {
            if(share_match_id<0){
                message.error("id<0")
            }else{
                const result = await api.getScoreInfo(share_match_id,'score');
                setGameScore(result.slice(1)); // 记住这里的第一个是game result,从第二项开始才是每一个game的比分
                let tmp_noScoreTd=[]
                for (let i = result.length; i < 8; i++) {
                    tmp_noScoreTd.push(<td key={i}>-</td>)
                }
                setScoreTd(tmp_noScoreTd)
            }

        } catch (e) {
            message.error(errorInfo(e));
        }
    };

    useEffect(() => getGameScore().then(()=>{

    }),[]);


    return (
        <div className="mobile-page">
            {/*<div className="mobile-subtitle">*/}
            {/*    全局比分*/}
            {/*</div>*/}
            <div className={'score-table'}>
            <table>
                <thead>
                <tr>
                    <th style={{ height: "40px", width: "62px" }} >
                        局数
                    </th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th className="row-head" >
                        {(state.matchInfo.players[0]).length>1?<div>
                            <div>{state.matchInfo.players[0][0]}</div>
                            <div>{state.matchInfo.players[0][1]}</div></div>
                        :state.matchInfo.players[0]
                        }
                    </th>
                    {gameScore.map((item, index) => {
                        return (<td className={item[0]>item[1]? 'win' : 'lose'} key={index}>
                            {item[0]}
                        </td>)
                    })}
                    {noScoreTd}
                </tr>
                <tr>
                    <th className="row-head" >
                        {(state.matchInfo.players[1]).length>1?<div>
                                <div>{state.matchInfo.players[1][0]}</div>
                                <div>{state.matchInfo.players[1][1]}</div></div>
                            :state.matchInfo.players[1]
                        }
                    </th>
                    {gameScore.map((item, index) => {
                        return (<td className={item[0]<item[1] ? 'win' : 'lose'} key={index}>
                            {item[1]}
                        </td>)
                    })}
                    {noScoreTd}
                </tr>
                </tbody>
            </table>
            </div>


        </div>
    )

}

export default GameScorePage