import React from 'react';
import './LoadingSpinner.css'; // 导入CSS样式

const MyLoading = () => {
    return (
        <div className="loader-container">
            <div className="loader"></div>
        </div>
    );
};

export default MyLoading;
