import React, {useContext, useState} from "react";
import {Button, Card, Checkbox, message, Popconfirm, Table} from "antd";
import ModifyMatch from "./modify";
import MatchInfo from "../../../data_structure/MatchInfo";
import api from "../../../api/api";
import {store} from "../../../store";
import cookie from "react-cookies";
import defaultIcon from "./../../../media/defaultIcon.png"
// import {hostname} from "../../../api/config";
import "./index.css"
const mydata = [
    {
        date: "2021-08-90 19:00",
        location: "中国",
        type: "公开赛",
        competition: "男团",
        round: "半决赛",
        players: ["马龙", "许昕"],
        players_head:[{"cn_name":"马龙","en_name":"MA Long","headshot":"none"},{"cn_name":"许昕","en_name":"XU Xin","headshot":"none"}]
    },
];
const myBr=<br/>
export default function TableList({data = mydata, changePage}) {
    const {state, dispatch} = useContext(store);
    const list = data.sort((a,b)=>b.date-a.date)
    const cardCover = (players) => {
        //TODO 要先解析csv文件，做一个dict. 要注意查询的效率，用到再查，不能多次使用这个63kb的文件
        // console.log("players,",players)
        return (
            <div className={'card-cover'}>
            <div className="card-player-score">
                <div className="card-player-info left">
                    {players[0]&&players[0].length>1?
                        <div className='card-player-info-icon2'>
                            <img id='icon' className="icon-img" width={'20px'} src={(players[0]?(players[0][0]["headshot"]):defaultIcon)} alt='选手头像'
                                 onError={(e) => { e.target.onerror = null;e.target.src=defaultIcon}}/>
                            <img id='icon' className="icon-img" width={'20px'} src={(players[0]?(players[0][1]["headshot"]):defaultIcon)} alt='选手头像'
                                 onError={(e) => { e.target.onerror = null;e.target.src=defaultIcon}}/>

                        </div>
                        :<div className='card-player-info-icon'>
                            <img id='icon' className="icon-img" width={'42px'} src={(players[0]?(players[0][0]["headshot"]):defaultIcon)} alt='选手头像'
                                 onError={(e) => { e.target.onerror = null;e.target.src=defaultIcon}}/>
                        </div>
                    }

                    <div >
                        {(players[0]?  (players[0].length>1 ?
                            <div > {players[0][0]["cn_name"]} <br/> {players[0][1]["cn_name"]}</div>
                            :<div style={{padding:'10px 0'}}>{players[0][0]["cn_name"]}</div>) : '未知')}
                    </div>
                </div>
                <div className="match-score">
                    <div className="match-score-vs">vs</div>

                </div>
                <div className="card-player-info right" >
                    {players[1]&&players[1].length>1?
                        <div className='card-player-info-icon2'>
                            <img id='icon' className="icon-img" width={'20px'} src={(players[1]?(players[1][0]["headshot"]):defaultIcon)} alt='选手头像'
                                 onError={(e) => { e.target.onerror = null;e.target.src=defaultIcon}}/>
                            <img id='icon' className="icon-img" width={'20px'} src={(players[1]?(players[1][1]["headshot"]):defaultIcon)} alt='选手头像'
                                 onError={(e) => { e.target.onerror = null;e.target.src=defaultIcon}}/>

                        </div>
                        :
                    <div className='card-player-info-icon'>
                        <img id='icon' className="icon-img" src={(players[1]?(players[1][0]["headshot"]):defaultIcon)} alt='选手头像'
                             onError={(e) => { e.target.onerror = null;e.target.src=defaultIcon}} />
                    </div>}
                    <div style={{textAlign:'center'}}>{(players[1]?  (players[1].length>1 ?
                        <div> { players[1][0]["cn_name"]} <br/> {players[1][1]["cn_name"]}</div>:
                            <div style={{padding:'10px 0'}}>{players[1][0]["cn_name"]}</div>
                    ) : '未知')}</div>
                </div>
            </div>
            </div>
        )
    }
    return (
      <>
          <div className='card-wrapper' >
              {
                  list && list.length === 0 ? null:
                      list.map((item, index) => {
                          return (
                              <Card
                                  hoverable
                                  key={'page'+index}
                                  cover={cardCover(item.players_head, item.m_id)}
                                  onClick={() => {
                                      // console.log("curr,index",curr,record,index)
                                      cookie.save('match_id',item.m_id,{path:'/'})
                                      dispatch({
                                          type:'setMatchInfoById',
                                          payload:{matchInfo:new MatchInfo(item),match_id:item.m_id}
                                      })

                                      let link = `/#/game/${item.m_id}`
                                      window.open(link, '_blank')
                                  }}
                                  className="card"
                              >
                                  <div className='card-content' style={{textAlign:'center'}}>
                                      <div>
                                          { item.date.slice(0,4)+'-'+item.date.slice(4,6)+'-'+item.date.slice(6,8)+' '+item.location }
                                      </div>
                                      <span style={{ height: '28px',verticalAlign:'center' }}>
                                          { MatchInfo.TYPE.find(t => t.key === item.type)?MatchInfo.TYPE.find(t => t.key === item.type).label:null }
                                          {" "}
                                          { MatchInfo.COMPETITION.find(t => t.key === item.competition)?MatchInfo.COMPETITION.find(t => t.key === item.competition).label:null }
                                          {MatchInfo.ROUND.find(t => t.key === item.round)?MatchInfo.ROUND.find(t => t.key === item.round).label:null}
                                      </span>
                                      {/*<span style={{height:'20px'}}>{item.has_data}{ item.has_video }</span>*/}
                                  </div>
                              </Card>)
                      })
              }
          </div >


      </>
    );
}
