import React, {useCallback, useContext, useEffect, useState} from "react";
import {Button, DatePicker, Form, Input, message, Modal,} from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import MatchTypeSelect from "../../components/MatchTypeSelect";
import MatchCompetitionSelect from "../../components/MatchCompetitionSelect";
import MatchRoundSelect from "../../components/MatchRoundSelect";
import api from "../../../api/api";
import MatchInfo from "../../../data_structure/MatchInfo";
import VideoInput from "./VideoInput";
import ProjectInput from "./ProjectInput";
import onProgress from "./progress";
import {store} from "../../../store";
import cookie from "react-cookies";
import LoginToCollect from "../../components/user/LoginToCollect";
// import {defaultToken} from "../../../api/config";
// import {inject, observer} from "mobx-react";

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 2, span: 18},
};
export default function AddMatch() {
  const visible = true;
  const close = false;

  const {state,dispatch} = useContext(store)
  const data = state
  const [form] = Form.useForm();
  const [videoFile, setVideoFile] = useState(null);
  const [projFile, setProjFile] = useState(null);
  const [pureVideoFile,setPureVideoFile] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onChangeForm = (key, value) => form.setFieldsValue({[key]: value});

  const logout=()=>{
    setVideoFile(null)
    setProjFile(null)
    dispatch({type:'logoutCollector'})
  }
  const onFinishAdd = async (values) => {
    if(!state.hasLoginAsCollector){
      showModal()
    }
    const params = new MatchInfo({
      date: moment(values["date"]).format("YYYYMMDD"),
      location: values.location,
      type: values.type,
      competition: values.competition,
      round: values.round,
      players: [
        values["playersA"].split("/"),
        values["playersB"].split("/"),
      ],
      has_data:projFile!==null,
      has_video:videoFile!==null,
      has_purevideo:pureVideoFile!==null
    });
    const result = await api.newMatch(params, videoFile, pureVideoFile, projFile,state.collectorInfo[0],state.collectorInfo[1], onProgress(params.genName()));

    if (result!==null && result.succeed===true) {
      if (result.info.toString().startsWith("duplicate")){
        let message_str = "重复，数据库已有此比赛记录"
        if (result.info.toString().includes("_data")){
          message_str+="；补充比赛数据"
        }
        if (result.info.toString().includes("_video")){
          message_str+="；补充比赛视频"
        }
        if (result.info.toString().includes("_purevideo")){
          message_str+="；补充纯净版视频"
        }
        message.success(message_str)
      }
      else if(result.info && result.info==="root_update_data"){
        message.success("管理员更新比赛信息！");
      }else if(result.info && result.info==="root_update_video"){
        message.success("管理员更新比赛视频！");
      }

      else if(result.info && result.info==="root_update_two"){
        message.success("管理员更新比赛信息和视频！");
      }
      else if(result.info && result.info==="root_update_purevideo"){
        message.success("管理员更新纯净版视频！");
      }
      else if(result.info && result.info==="root_update_three"){
        message.success("管理员更新视频、数据和纯净版视频！");
      }
      else if(result.info && result.info==="root_update_two_pure_and_video"){
        message.success("管理员更新纯净版视频和视频！");
      }
      else if(result.info && result.info==="root_update_two_pure_and_data"){
        message.success("管理员更新纯净版视频和数据！");
      }


      else{
        message.success("增加比赛信息成功！");
      }


    } else message.error("增加比赛信息失败！");
  };
  const onReset = useCallback(() => {form.resetFields();setProj(null);setVideo(null);setPureVideo(null)}, [form]);

  const autoFill = useCallback(matchInfo => {
    form.setFieldsValue({
      date: moment(matchInfo.date.replace(
        /^(\d{4})(\d{2})(\d{2})$/,
        "$1-$2-$3"
      )),
      location: matchInfo.location,
      type: matchInfo.type,
      competition: matchInfo.competition,
      round: matchInfo.round,
      playersA: matchInfo.players[0].join("/"),
      playersB: matchInfo.players[1].join("/"),
    })
  }, [form]);
  const setProj = useCallback(projFile => {
    if (!projFile) {
      setProjFile(null);
      return;
    }
    setProjFile(projFile);
    MatchInfo.fromFile(projFile).then(res => autoFill(res));
  }, [autoFill]);
  const setVideo = useCallback(videoFile => {
    if (!videoFile) {
      setVideoFile(null);
      return;
    }
    setVideoFile(videoFile);
    if (projFile === null)
      autoFill(MatchInfo.fromName(videoFile.name, state.player_list));
  }, [autoFill, state.player_list, projFile]);
  const setPureVideo = useCallback(pureVideoFile => {
    if (!pureVideoFile) {
      setPureVideoFile(null);
      return;
    }
    setPureVideoFile(pureVideoFile);
    if (projFile === null)
      autoFill(MatchInfo.fromName(pureVideoFile.name, state.player_list));
  }, [autoFill, state.player_list, projFile]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);

  };
  useEffect(() => onReset(), [onReset, visible]);

  useEffect(()=>{if(!state.hasLoginAsCollector){
    showModal()
  }},[]
  )
  return (
    <div style={{margin:10}}>
      <Modal title="采集员登录" open={isModalOpen} closable={true} footer={[<Button key={'cancel'} onClick={()=>setIsModalOpen(false)}>取消</Button>]}>
        <LoginToCollect closeModal={()=>setIsModalOpen(false)} aftLogin={(uname,upwd)=>dispatch({type:'loginCollector',payload:{username:uname,password:upwd}})} />
      </Modal>
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinishAdd}>
        <div style={{textAlign:'center',fontSize:'15px',color:'#0b286b'}}>信息录入</div>
        <div style={{width:'100%',height:'2px',backgroundColor:'#0b286b'}}/>
        <Form.Item name="date" label="比赛日期" rules={[{required: true}]}>
          <DatePicker
            placeholder="请选择比赛日期"
            size="middle"
            locale={locale}
            style={{width: 354}}
            onChange={date => onChangeForm("date", date)}
          />
        </Form.Item>
        <Form.Item name="location" label="比赛地点" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("location", e.target.value)}/>
        </Form.Item>
        <Form.Item name="type" label="比赛类型" rules={[{required: true}]}>
          <MatchTypeSelect onChange={value => onChangeForm("type", value)}/>
        </Form.Item>
        <Form.Item name="competition" label="比赛项目" rules={[{required: true}]}>
          <MatchCompetitionSelect onChange={value => onChangeForm("competition", value)}/>
        </Form.Item>
        <Form.Item name="round" label="比赛轮次" rules={[{required: true}]}>
          <MatchRoundSelect onChange={value => onChangeForm("round", value)}/>
        </Form.Item>
        <Form.Item name="playersA" label="A队球员" rules={[{required: true}]}
                   extra={"单名球员直接输入，两名球员用/分开"}>
          <Input onChange={e => onChangeForm("playersA", e.target.value)}/>
        </Form.Item>
        <Form.Item name="playersB" label="B队球员" rules={[{required: true}]}
                   extra={"单名球员直接输入，两名球员用/分开"}>
          <Input onChange={e => onChangeForm("playersB", e.target.value)}/>
        </Form.Item>
        <div style={{textAlign:'center',fontSize:'15px',color:'#0b286b'}}>文件上传</div>
        <div style={{width:'100%',height:'2px',backgroundColor:'#0b286b'}}/>
        <Form.Item label={"数据文件"}>
          <ProjectInput onChange={setProj}/>
        </Form.Item>
        <Form.Item label={"视频文件"}>
          <VideoInput onChange={setVideo}/>
        </Form.Item>
        <Form.Item label={"纯净版视频"}>
          <VideoInput onChange={setPureVideo}/>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
            确认
          </Button>
          <Button htmlType="button" onClick={onReset} style={{margin: "0 20px"}}>
            重置
          </Button>
          <Button htmlType="button" onClick={()=>{if(state.hasLoginAsCollector) logout(); else showModal()}} style={{margin: "0 20px"}}>
            {state.hasLoginAsCollector?'注销':'登录'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
