import React, {useContext, useEffect, useState} from "react";
// import './index.css'
import DetailTable from "../../components/DetailTable";
import {store} from "../../../store";
import Swiper from 'react-id-swiper';
import {message} from "antd";
import api, {errorInfo} from "../../../api/api";
import MultiTable from "../../components/DetailTable/MultiTable";
import tableTennisIcon from "../../../media/tt-white.png";
// 三段法表格

function StageAnalysisPage(props) {


    const {state, dispatch} = useContext(store);

    const match_id=window.location.href.split('/').reverse()[1]==='game'?parseInt(window.location.href.split('/').reverse()[0]):-2;//-2 means not shared

    const [detailTableData, setDetailTableData] = useState([])
    function toPercent(point){
        var percent = Number(point*100).toFixed(1);
        percent += "%";
        return percent;
    }

    const JudgeD = (competition)=>{
        // console.log("JudgeD",competition,competition === "MenD" || competition ==="WomenD" || competition ==="MixedD")
        return competition === "MenD" || competition ==="WomenD" || competition ==="MixedD"
    }
    const getMultiOr3StageTable = async ()=> {
        try{
            const result = await api.getScoreInfo(match_id,JudgeD(state.matchInfo.competition)?'multi':'3ppstage');
            // console.log("result,3stage",match_id,JudgeD(state.matchInfo.competition),result)
            setDetailTableData(result)
        }catch (e) {
            message.error(errorInfo(e))
        }
    };
 

    useEffect(() => getMultiOr3StageTable()
    ,[state.matchInfo,state.match_id])

    return(
        <div className="mobile-page">
            <div className="mobile-subtitle">
                <img src={tableTennisIcon} width={26} style={{position:'absolute',left:2,top:2}}/>
                <div>三段法分析</div>
            </div>

            {(state.matchInfo&&JudgeD(state.matchInfo.competition))?
                <MultiTable swiper={props.swiper} setIdx={props.setIdx} data={detailTableData} match_id={match_id} competition={state.matchInfo.competition} players={state.matchInfo.players}/>
                :
                <DetailTable swiper={props.swiper} setIdx={props.setIdx} data={detailTableData} match_id={match_id} competition={state.matchInfo.competition} players={state.matchInfo.players}/>
                }

        </div>
    )


}

export default StageAnalysisPage;

