import React, {createContext, useReducer, useEffect} from 'react';
import initialState from './InitData';
import reducer from './reducer';
import api, {errorInfo} from "../api/api";
import {message} from "antd";
import MatchInfo from "../data_structure/MatchInfo";
import cookie from "react-cookies";

// 创建数据中心的这样一个上下文，一般称为store
const store = createContext();
const NOT_SHARED = -2;

// 包装成数据组件
function StateProvider({children}) {
    // 绑定数据以及数据处理方法
    const [state, dispatch] = useReducer(reducer, initialState);
    const share_match_id=window.location.href.split('/').reverse()[1]==='game'?parseInt(window.location.href.split('/').reverse()[0]):NOT_SHARED;//-2 means not shared

    // console.log("props,stateprovider",share_match_id)


    const getDetailMatchInfo = async (match_id)=> {

        try{
            const result = await api.getMatchList_i()
            for(let i =0;i<result.length;i++){
                if(result[i].m_id === match_id){
                    dispatch({
                        type:'setMatchInfoById',
                        payload:{matchInfo:new MatchInfo(result[i]),match_id:match_id}
                    })
                    console.log("set init match_id",match_id,state.match_id)
                }
            }
            // dispatch({
            //     type:'setMatchInfoById',payload:match_id
            // })

        }catch (e) {
            message.error(errorInfo(e))
        }
    };
    // 初始化时，读取本地数据
    useEffect(() => {
        if(share_match_id===NOT_SHARED){
            // if(cookie.load('match_id')===undefined){
            //     cookie.save('match_id',1470,{path:'/'})
            //     getDetailMatchInfo(1470)
            // }else {
            //     getDetailMatchInfo(cookie.load('match_id'))
            // }
        }else{
            getDetailMatchInfo(share_match_id)
            getPlayerList_()
        }

    }, [share_match_id])
    const getScoreTimeDetail = async () => {
        try {
            if(state.match_id > 0 && share_match_id>0){
                await api.getScoreDetail(share_match_id).then(res=>{dispatch({
                    type:'updateGameDetail',
                    payload:res.list
                });dispatch({
                    type:'updateGameResult',
                    payload:res.result
                });
                })
            }


        } catch (e) {
            message.error(errorInfo(e));
        }
    };
    const getPlayerList_ = async () => {
        try {

                await api.getPlayerList().then(res=>dispatch({
                    type:'setPlayerList',
                    payload:res
                }))

        } catch (e) {
            message.error(errorInfo(e));
        }
    };
    useEffect(()=>{
        if(share_match_id>0){
            if(state.matchInfo.has_data)getScoreTimeDetail()
            // message.success("更新detail"+state.match_id+"()")
        }
    },[state.match_id,state.matchInfo])
    // 为子元素包裹上数据的上下文环境，方便所有子元素读取
    return <store.Provider value={{state, dispatch}}>
        {children}
    </store.Provider>
}

export {store, StateProvider}
