import React from 'react';
import pingxieLogo from './../../media/logo_pingxie.jpg'
export default function MyHeader ()  {


        return (<div>
            <div style={{zIndex:100, backgroundColor:'#0b286b',padding:'10px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <span ><img src={pingxieLogo} style={{width:30,height:30}}/></span>
                <span onClick={()=>
                    window.open( `/#/`, '_blank')}
                    style={{color:"white",margin:'5px'}} >中国乒乓球大数据平台</span>
            </div>


        </div>)

}