
// 初始数据
// const initialState = {
//     m_id: 1474,
//     date: "20211127",
//     location: "休斯顿",
//     type: "WorldChampion",
//     competition: "WomenS",
//     round: "Round16",
//     players: ["伊藤美诚", "素塔西尼"],
//
// };
import MatchInfo from "../data_structure/MatchInfo";
import listData from "./gameDetail.json"

const initialState = {

    matchInfo: new MatchInfo(
        // {"date": '20211127', "location": "休斯顿", "type": "WorldChampion", "competition": "WomenS", "round": "Round16", "players": [["伊藤美诚"], ["素塔西尼"]], "status": "finish", "exist_video": false, "id": 1474}
        {
            "date": null,
            "location": "",
            "type": "OtherType",
            "competition": "WomenD",
            "round": "OtherRound",
            "players": [[null, null], [null, null]],
            "status": "finish",
            "has_video": false,
            "has_data":false,
            "has_purevideo": false
            // "id": 1497
        }
    ),
    match_id: window.location.href.split('/').reverse()[1]==='game'?parseInt(window.location.href.split('/').reverse()[0]):-1,
    // 1474
    //     1497,
    gameDetail : listData.list,
    selectDetail:{
        "m_id":0,
        "table_idx":0,
        "col_idx":0,
        "team_idx":0,
        "row_idx":0,
        "list_content":[]
    },
    player_list:[],
    anaInfo:{
        table_idx:0,
        col_idx:0
    },
    gameResult:[null,null],
    hasLoginAsCollector:false,
    collectorInfo:[0,0]
}

export default initialState;
