import {notification} from "antd";

const onProgress = key => (message, progress) => {

    notification.open({
        key,
        duration: progress === 100 ? 2 : 0,
        message,
        description: `${progress.toFixed(2)}%`
    })
}

export default onProgress;
