import "./App.css";
import {Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import "moment/locale/zh-cn";
import RouterComm from "./pages/router";
import {StateProvider} from "./../store/index";
import "./css/origin1.css"
// import "./css/origin2.css"

import "./css/origin3.css"
// import "./css/origin-index.css"
import "./css/origin-main.css"
import "./css/video-js.css"


export default function App() {
    return <StateProvider>
        <BrowserRouter>
            <Switch>
                <RouterComm/>
                {/*<Homepage/>*/}
           </Switch>
        </BrowserRouter>
    </StateProvider>;
}
