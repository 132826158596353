import api, {errorInfo} from "../api/api";
import MatchInfo from "../data_structure/MatchInfo";
import {message} from "antd";

const reducer = (state, action) => {
    switch (action.type) {
        case 'setMatchInfoById': // payload is the id ，为什么用id确定呢，因为比如说一个报表，可能就只有信息就是比赛的id
                console.log(
                    "setMatchInfoById",action
                )
                return {
                    ...state,
                    matchInfo: action.payload.matchInfo,
                    match_id:action.payload.match_id
                };
        case 'updateGameDetail':
            return {
                ...state,
                gameDetail:action.payload
            }
        case 'setSelectDetail':{
            return {
                ...state,
                selectDetail:action.payload
            }
        }
        case 'setPlayerList':{
            return {
                ...state,
                player_list:action.payload
            }
        }
        case 'setAnaInfo':{
            return {
                ...state,
                anaInfo:action.payload
            }
        }
        case 'updateGameResult':{
            return {
                ...state,
                gameResult:action.payload
            }
        }
        case 'loginCollector':{
            return {
                ...state,
                hasLoginAsCollector: true,
                collectorInfo: [action.payload.username,action.payload.password]
            }
        }
        case 'logoutCollector':{
            return {
                ...state,
                hasLoginAsCollector: false,
                collectorInfo: []
            }
        }

        // case 'setMatchId': // payload is the id ，为什么用id确定呢，因为比如说一个报表，可能就只有信息就是比赛的id
        //
        //     return {
        //         ...state,
        //         match_id: action.payload
        //     };

        //    视频不可能存在前端，但是其他数据呢？好像反复请求也不是什么大问题

        // case 'init': {
        //     let newData = [];
        //
        //     console.log(action.payload);
        //     newData = action.payload;
        //     return {
        //         ...state,
        //         data: newData
        //     };
        // }
        default:
            throw new Error();
    }
}

export default reducer;
