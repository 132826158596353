import React, {useEffect, useState} from "react";
import {AutoComplete, Button, DatePicker, Divider, Input, Layout, message, Spin, Pagination, Checkbox} from "antd";
import TableList from "./table";
import "./index.css";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment";
import MatchTypeSelect from "../../components/MatchTypeSelect";
import MatchCompetitionSelect from "../../components/MatchCompetitionSelect";
import MatchRoundSelect from "../../components/MatchRoundSelect";
import api, {errorInfo} from "../../../api/api";


export default function Match() {
  const [tableData, setTableData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [selectDate,setSelectDate] = useState(null)
  const [selectType,setSelectType] = useState(null)
  const [selectCompetition,setCompetition] = useState(null)
  const [selectRound,setRound] = useState(null)
  const [selectPlayer,setPlayer] = useState(null)
  // const [hasData,setHasData] = useState(true)
  // const [hasVideo,setHasVideo] = useState(true)

  const per_page = 20
  const pageNumberOnChange = (p) =>{
    setPage(p)
  }

  const isMobile = ()=> {
    let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    return flag;
  }
  const playerList = ()=>{
    let init_player_list=[]
    tableData.map(item=>{
      let retStr='';
      let tmpList = (item.players[0].concat(item.players[1]))
      for(let i=0;i<tmpList.length-1;i++){
        retStr+=tmpList[i]+' '
      }
      retStr+=tmpList[tmpList.length-1]
      for(let i=0;i<tmpList.length;i++) {
        let single_player_with_en_name=tmpList[i]
        if(i===0){
          single_player_with_en_name=item.players_head[0][0]["en_name"]==='none'?'':':'+item.players_head[0][0]["en_name"]
        }else if(tmpList.length===2 && i===1){
          single_player_with_en_name=item.players_head[1][0]["en_name"]==='none'?'':':'+item.players_head[1][0]["en_name"]
        }else if(tmpList.length===4 && i===1){
          single_player_with_en_name=item.players_head[0][1]["en_name"]==='none'?'':':'+item.players_head[0][1]["en_name"]
        }else if(tmpList.length===4 && i===2){
          single_player_with_en_name=item.players_head[1][0]["en_name"]==='none'?'':':'+item.players_head[1][0]["en_name"]
        }else if(tmpList.length===4 && i===3){
          single_player_with_en_name=item.players_head[1][1]["en_name"]==='none'?'':':'+item.players_head[1][1]["en_name"]
        }
        init_player_list.push(tmpList[i]+single_player_with_en_name)
      }
      init_player_list.push(retStr)
      return retStr
    })
    return init_player_list
  }
 //'pl1 pl2 pl3 pl4' or 'pl1 pl2'

  const playerListUnique = [...new Set(playerList())]; // 去除重复，比如马龙和奥的比赛都叫做 ‘马龙 奥’，playerList出现一次就行了


  const getList = async () => {
    try {
      const result = await api.getMatchList_i();
      setTableData(result);
      // setTableData(result.filter(item=>(item['has_data']===hasData && item['has_video']===hasVideo)));
      setOriginData(result);
      setTotalCount(result.length)
      // const player_name_dict = api.getPlayerNameDictList()
      // console.log("player_name_dict",player_name_dict)
    } catch (e) {
      message.error(errorInfo(e));
    }
  };
  const checkAllFilter = (key, values) => {
    console.log("check all filters",key, values);
    //这个和下面不一样因为不可能是null
    // const filteredData_hasData_has_Video = originData.filter(item=>(item['has_data']===(key==='has_data'?values:hasData) && item['has_video']===(key==='has_video'?values:hasVideo)))
    const filteredData_hasData_has_Video = originData
    //date
    // console.log("filteredData_hasData_has_Video",filteredData_hasData_has_Video)
    const filteredData_date = (key==='date' && values !== null && values !== undefined)?
        filteredData_hasData_has_Video.filter(item=>item['date'].substring(0,4)===values) : filteredData_hasData_has_Video.filter(item=>selectDate===null || item['date'].substring(0,4)===(moment(selectDate).format("YYYY")))

    //type
    const filteredData_type = (key==='type' && values !== null && values !== undefined)?
        filteredData_date.filter(item=>item['type']===values) : filteredData_date.filter(item=>selectType===null || item['type']===selectType)
    //competition
    const filteredData_competition = (key==='competition' && values !== null && values !== undefined)?
        filteredData_type.filter(item=>item['competition']===values) : filteredData_type.filter(item=>selectCompetition===null || item['competition']===selectCompetition)
    //round
    const filteredData_round = (key==='round' && values !== null && values !== undefined)?
        filteredData_competition.filter(item=>item['round']===values) : filteredData_competition.filter(item=>selectRound===null || item['round']===selectRound)
    //player
    // if(key==='players')console.log("values=",values,filteredData_round)
    const filteredData_player = (key==='players' && values !== null && values !== undefined)?
        filteredData_round.filter(item=>{
          const pList = values.split(":")[0].split(" ");
          // if(item.players.some(side => side.some(p => p.includes('伊藤美诚')))){
          //   console.log("--:",pList,item,pList.every(onePlayer => item.players.some(side => side.some(p => p.includes(onePlayer)))))
          // }
          return pList.every(onePlayer => item.players.some(side => side.some(p => p.includes(onePlayer))));
        }) : filteredData_round.filter(item=>{
          if(selectPlayer===null || selectPlayer===undefined)return true
          if(values===null || values ===undefined)return true;
          const pList = selectPlayer.split(" ");
          return pList.every(onePlayer => item.players.some(side => side.some(p => p.includes(onePlayer))));
        })
    setTableData(filteredData_player)
    setTotalCount(filteredData_player.length)
    return filteredData_player
  }
  const filterTable = (key, values) => {
    console.log(key, values);
    if (values !== null && values !== undefined) {
      const filteredData = tableData.filter(item => {
        if (key === "players") {
          const pList = values.split(" ");
          return pList.every(onePlayer => item.players.some(side => side.some(p => p.includes(onePlayer))));
        } else return item[key] === values;
      });
      setTableData(filteredData);
      setTotalCount(filteredData.length)
    } else {
      // setTableData(originData);
    }
  };
  useEffect(() => getList(), []);

  function clearSelection() {
    setRound(null);
    setPlayer(null);
    setSelectType(null);
    // setHasData(true);
    // setHasVideo(true);
    setSelectDate(null);
    setCompetition(null);
  }

  return (
    <>
      <div className={"list-container"} >
        <div  style={{width:'100%',textAlign:'center',alignItems:'center'}}>
          <div style={{width:'100%',display:'flex',textAlign:'justify',marginTop:'0.8%',marginLeft:'3%'}}>
            <Button
                className="reset-btn"
                type="primary"
                onClick={() => {getList().then(()=>clearSelection())}}
            >
              重置
            </Button>
            {/*<Checkbox className={"checkbox-btn"} checked={hasData} onChange={e => {*/}
            {/*  checkAllFilter("has_data", e.target.checked);*/}
            {/*  setHasData(e.target.checked)*/}
            {/*}}>有数据</Checkbox>*/}
            {/*<Checkbox className={"checkbox-btn"} checked={hasVideo} onChange={e => {*/}
            {/*  checkAllFilter("has_video", e.target.checked);*/}
            {/*  setHasVideo(e.target.checked)*/}
            {/*}}>有视频</Checkbox>*/}
            <DatePicker
                className="data-picker"
                locale={locale}
                // value={Date.parse(selectDate)}
                value={selectDate}
                placeholder="年份"
                picker={'year'}
                format={"YYYY"}
                onChange={date => {console.log("year=",date,(moment(date).format("YYYY")));checkAllFilter("date", moment(date).format("YYYY"));setSelectDate(date)}}
            />
          </div>
          <div style={{width:'100%',display:'flex',textAlign:'justify',marginTop:'0.8%',marginLeft:'3%'}}>
          <MatchTypeSelect
              className="type-select"
              value={selectType}
              onChange={value => {checkAllFilter("type", value);setSelectType(value)}}/>
          <MatchCompetitionSelect
              className="type-select"
              value={selectCompetition}
              onChange={value => {checkAllFilter("competition", value);setCompetition(value)}}/>
          <MatchRoundSelect
              className="type-select-right"
              value={selectRound}
              onChange={value => {checkAllFilter("round", value);setRound(value)}}/>
          </div>
          <div style={{width:'100%',textAlign:'justify',marginTop:'0.8%',marginLeft:'3%'}}>
            <AutoComplete
                allowClear={true}
                style={{
                  width: '93%'
                }}
                onClear={()=>{checkAllFilter("players", null);setPlayer(null)}}
                onSelect={(value) => {checkAllFilter("players", value);setPlayer(value)}}
                dataSource={playerListUnique}
                filterOption={(inputValue, option) => {

                  return  option.props.children.toLowerCase().includes(inputValue.toLowerCase())
                }
                }
                placeholder="输入搜索条件，球员间空格分隔"
            />
          </div>
        </div>

        <Layout className='card-list-container' >
              <TableList data={tableData.sort((a,b)=>b.date-a.date).filter((i,idx)=>(idx>=(page-1)*per_page)&&(idx<(page)*per_page))} changePage={getList}/>
            </Layout>
          <div className="foot-pagination">
            <Divider />
              <Pagination size="small" current={page} total={totalCount} pageSize={per_page} onChange={pageNumberOnChange} />
          </div>

      </div>

    </>
  );
}
