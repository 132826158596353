import React, {useState, useEffect, useRef, useContext} from "react";
import {Button, Checkbox, message, Radio, Switch} from 'antd'
import Swiper from 'react-id-swiper';
import VideoJS from "../../components/ShowVideo";
import '../VideoPage/index.css'
import {store} from "../../../store";
import api, {errorInfo} from "../../../api/api";
import tableTennisIcon from "../../../media/tt-white.png";
import MatchInfo from "../../../data_structure/MatchInfo";
import TouchOverlay from '../VideoPage/Dragtime';
import videojs from "video.js";

const JumpDetailPage = (props) => {
    // console.log("props link",props)
    const {state,dispatch}=useContext(store)
    const analysisTableIdx = state.selectDetail.table_idx;
    const analysisTeamIdx = state.selectDetail.team_idx;// only in double.
    const analysisColIdx = state.selectDetail.col_idx;
    const analysisRowIdx = state.selectDetail.row_idx;
    const match_id = state.match_id;

    const videoUrl = api.getVideoUrl(match_id)
    // const [players, setPlayers] = useState([[],[]])
    const [players, setPlayers] = useState(state.matchInfo.players)
    const playerRef = useRef(null);
    const activeIndex = props.activeIndex
    const [pointButtons, setPointButtons] = useState(null)
    const [currentTime, setCurrentTime] = useState(0)
    const [pauseTime, setPauseTime] = useState(0)
    const [ifPause, setIfPause] = useState(true)
    // const [continuesPlay,setContinuesPlay] = useState(0)
    const [pointIdx, setPointIdx] = useState(0)
    const [ifContinous, setIfContinous] = useState(false)
    const [playTimeList,setPlayTimeList] = useState([])
    const Component = videojs.getComponent("Component");

    const setPlayerById = ()=>{
        setPlayers(state?state.matchInfo.players:[['-'],['-']])
    }
    useEffect(()=>setPlayerById(),[])
    const getScoreTimeDetail = async () => {
        try {
            if(props.match_id>0)
                await api.getScoreDetail(props.match_id).then(r=>console.log(r))

        } catch (e) {
            message.error(errorInfo(e));
        }
    };
    useEffect(()=> getScoreTimeDetail(),[])

    useEffect(() => {
        if (activeIndex === 3) {
            setIfPause(false)
        } else {
            setIfPause(true)
        }
    },[activeIndex])


    const videoJsOptions = {
        autoplay: false,
        controls: true,
        bigPlayButton: true,
        responsive: true,
        fluid: true,
        preload: 'auto',
        language: 'zh-CN', // 设置语言
        controlBar: {
            children: [
                { name: 'playToggle' },
                { name: 'currentTimeDisplay' }, // 当前已播放时间
                { name: 'progressControl' },
                { name: 'durationDisplay' }, // 总时间
                {
                    name: 'volumePanel', // 音量控制
                    inline:false
                },
                { name: 'FullscreenToggle' }, // 全屏
            ],
        },
        sources: [{
            src: videoUrl,
            type: 'video/mp4'
        }]
    }


    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
        Component.registerComponent("TouchOverlay", TouchOverlay);
        const touchOptions = {};
        const properIndex = player.children().indexOf(player.getChild("BigPlayButton"));
        player.addChild("TouchOverlay", touchOptions, properIndex);
    };


    useEffect(() => {
        let whole_game_rally = state.gameDetail;
        let num_game = whole_game_rally.length;
        let rally_to_show = state.selectDetail.list_content;
        let total = []
        // console.log("rally_to_show",rally_to_show,num_game,state.selectDetail.list_content.length)

        for( let idx_fake_game =0;idx_fake_game<state.selectDetail.list_content.length;idx_fake_game++){ //TODO
            let has_appendix = state.selectDetail.list_content.length === num_game+1 // 有什么特殊的比赛是分前半程后半程，list_content长度是6，game有五场
            let idx_game = has_appendix?(idx_fake_game===num_game?num_game-1:idx_fake_game):idx_fake_game
            // console.log("rally_to_show[idx_game]",rally_to_show[idx_game])
            let game_rally = state.gameDetail[idx_game].list
            let n = game_rally.length
            for (let i = 0; i < n; i++) {
                if(rally_to_show[idx_game].some(r=>r.rallyNo === i)){
                    let pro_rally_info = {
                        game_score:state.gameDetail[idx_game].score,
                        rally_info:game_rally[i]
                    }
                    // console.log("---",idx_game)
                    total.push(pro_rally_info)
                }
                if(has_appendix && idx_fake_game===num_game && rally_to_show[idx_fake_game].some(r=>r.rallyNo === i)){
                    let pro_rally_info = {
                        game_score:state.gameDetail[idx_game].score,
                        rally_info:game_rally[i]
                    }
                    // console.log("aaa",idx_fake_game,idx_game)
                    total.push(pro_rally_info)
                }
                // if(num_game<state.selectDetail.list_content.length){
                //     if(num_game*2===state.selectDetail.list_content.length){
                //         if(rally_to_show[idx_game+num_game].some(r=>r.rallyNo === i)){
                //             let pro_rally_info = {
                //                 game_score:state.gameDetail[idx_game].score,
                //                 rally_info:game_rally[i]
                //             }
                //             console.log("bbb")
                //             total.push(pro_rally_info)
                //         }
                //     }
                // }
            }
        }
        // for( let idx_game =0;idx_game<num_game && idx_game<state.selectDetail.list_content.length;idx_game++){
        //     console.log("rally_to_show[idx_game]",rally_to_show[idx_game])
        //     let game_rally = state.gameDetail[idx_game].list
        //     let n = game_rally.length
        //     for (let i = 0; i < n; i++) {
        //         if(rally_to_show[idx_game].some(r=>r.rallyNo === i)){
        //             let pro_rally_info = {
        //                 game_score:state.gameDetail[idx_game].score,
        //                 rally_info:game_rally[i]
        //             }
        //             total.push(pro_rally_info)
        //         }
        //         if(num_game<state.selectDetail.list_content.length){
        //             if(num_game*2===state.selectDetail.list_content.length){
        //                 if(rally_to_show[idx_game+num_game].some(r=>r.rallyNo === i)){
        //                     let pro_rally_info = {
        //                         game_score:state.gameDetail[idx_game].score,
        //                         rally_info:game_rally[i]
        //                     }
        //                     total.push(pro_rally_info)
        //                 }
        //             }
        //         }
        //     }
        // }
        console.log("total = ",total)
        let buttons = total.map((item, index) => {
            return (
                <Button className="point-button" key={index} id={"point-"+index} onClick={() => {
                    // console.log(item.rally_info.startTime,item.rally_info.endTime)
                    setCurrentTime(item.rally_info.startTime)
                    setPauseTime(item.rally_info.endTime)
                    setPointIdx(index)
                }}>
                    {/* serveSide 是发球方 */}
                    <div className="dot-1" style={{visibility: item.rally_info.winSide === 0 ? 'visible' : 'hidden'}}></div>
                    <div className="score-1">{item.rally_info.score[0]} </div>
                    <div className="rally-score-jump"
                    >{item.game_score[0]} : {item.game_score[1]}</div>
                    <div className="score-2">{item.rally_info.score[1]} </div>
                    <div className="dot-2" style={{visibility: item.rally_info.winSide === 1 ? 'visible' : 'hidden'}}></div>
                </Button>)
        })
            setPointButtons(buttons)
        setPlayTimeList(total.map(r=>{return {"startTime":r.rally_info.startTime,"endTime":r.rally_info.endTime}}))

    }, [state.selectDetail.list_content,state.gameDetail])


    // let rallyButtons  = video.map((item, index) => {
    //     return (
    //         <Radio.Button value={index+''} id={"rally-"+item.round} className="rally-button" key={index} onClick={() => { setPointDatas(item) }}>
    //             第{item.round}局
    //         </Radio.Button>)
    // })
    const switchChange = (checked) => {
        setIfContinous(checked)
    }
    const JudgeD = (competition)=>{
        // console.log("JudgeD",competition,competition === "MenD" || competition ==="WomenD" || competition ==="MixedD")
        return competition === "MenD" || competition ==="WomenD" || competition ==="MixedD"
    }

    const cal_name_double=()=>{
        let name_font='';
        if(analysisColIdx===4||analysisColIdx===5){
            name_font=players[analysisTeamIdx][0]+'/'+players[analysisTeamIdx][1]+'发球轮总计'
        }else if(analysisColIdx===2||analysisColIdx===3){
            name_font=players[analysisTeamIdx][0]+'/'+players[analysisTeamIdx][1]+'发球轮相持'
        }else{
            name_font=analysisTableIdx===0?players[analysisTeamIdx][0]+'发球'+players[analysisTeamIdx][1]+'第三拍':
                analysisTableIdx===1?players[analysisTeamIdx][1]+'接发球'+players[analysisTeamIdx][0]+'第四拍':
                    analysisTableIdx===2?players[analysisTeamIdx][1]+'发球'+players[analysisTeamIdx][0]+'第三拍': players[analysisTeamIdx][0]+'接发球'+players[analysisTeamIdx][1]+'第四拍'
        }
        return <div>{name_font}</div>

    }
    const cal_name_double_back=()=>{

        let name_back='';
        const player_name_idx=[[0,1],[1,0],[1,0],[0,1]]
        name_back=(analysisRowIdx===2?"":players[analysisTeamIdx][player_name_idx[analysisTableIdx][analysisRowIdx]])+(analysisColIdx%2===0?'得分':'失分')
        return<div>
            {name_back}
        </div>
    }
    const cal_name_single=()=>{
        let name_font=analysisTableIdx===0?players[0][0]:players[1][0];
        console.log('font',name_font)
        // const col_names=['发球','第三拍','接发球','第四拍','相持段','总计']
        // let name_back=col_names[analysisColIdx];
        // name_back+=(analysisRowIdx%2===0?'得分':'失分')
        return<div>
            {name_font}
        </div>
    }
    const cal_name_single_back=()=>{
        // let name_font=analysisTableIdx===0?players[0]:players[1];

        const col_names=['发球','第三拍','接发球','第四拍','相持段','总计']
        let name_back=col_names[analysisColIdx]?col_names[analysisColIdx]:'总计';
        name_back+=(analysisRowIdx%2===0?'得分':'失分')
        return<div>
            {name_back}
        </div>
    }
    useEffect(() => {
      // let id = 'point-' + pointIdx;
      // let idlast = 'point-' + (pointIdx-1)%4;
      //
      // let point = document.getElementById(id);
      // let pointlast = document.getElementById(idlast);
      // console.log(pointIdx,point)
      // if (point) {
      //   point.focus()
      // }
      // if (pointlast){
      //     pointlast.blur()
      // }
      // TODO 现在的问题就是跳转到下一个播放之后没有自动把start的那个blur,应该是css的问题
    },[pointIdx])
    const col_name_double=[['发球/第三拍','相持段','总计'],['接发球/第四拍','相持段','总计']]
    return (
        <div className="mobile-page">

            <div className="mobile-subtitle">
                <img src={tableTennisIcon} width={26} style={{position: 'absolute', left: 2, top: 2}}/>
                {(state.matchInfo && JudgeD(state.matchInfo.competition)) ? cal_name_double() : cal_name_single()}
            </div>
            <div style={{color: '#0b286b', justifySelf: 'center', marginTop: 2, fontSize: 14, fontWeight: 700}}>
                {(state.matchInfo && JudgeD(state.matchInfo.competition)) ? cal_name_double_back() : cal_name_single_back()}
            </div>

            <div className="video-container">
                {/*<Radio.Group className="video-rally-group" defaultValue={'0'}>*/}
                {/*    {rallyButtons}*/}
                {/*</Radio.Group>*/}
                {state.matchInfo.has_video ? <div className="video-player">
                    <VideoJS
                        options={videoJsOptions}
                        onReady={handlePlayerReady}
                        currentTime={currentTime}
                        pauseTime={pauseTime}
                        ifPause={ifPause}
                        // isFull={true}
                        ifContinous={ifContinous}

                        pointDatas={playTimeList}
                        pointIdx={pointIdx} setPointIdx={setPointIdx}
                    />
                </div> : null}
                <div className="video-banner">
                    <div className="banner-player">
                        <span className="player-name">
                            {(players[0]).length > 1 ? <div>
                                    <span>{players[0][0] + ' '}</span>
                                    <span>{players[0][1]}</span></div>
                                : players[0]
                            }
                        </span>
                        <span className="vs">vs</span>
                        <span className="player-name">
                            {(players[1]).length > 1 ? <div>
                                    <span>{players[1][0] + ' '}</span>
                                    <span>{players[1][1]}</span></div>
                                : players[1]
                            }
                        </span>
                    </div>
                    <div className={'video-divider'}>
                        <div className={'vs-divider'} style={{backgroundColor: '#587ec4', marginBottom: 5}}></div>
                        <div
                            className={'vs-result'}>{state.matchInfo.has_data ? state.gameResult[0] + '-' + state.gameResult[1] : null}</div>
                        <div className={'vs-divider'} style={{backgroundColor: '#fbb03b', marginBottom: 5}}></div>
                    </div>
                </div>
            </div>

            <div style={{width: "100%", position: 'relative', top: '-10px'}}>
                <Switch className="jump-switch" onChange={switchChange}></Switch>
                <span style={{
                    width: '100px',
                    position: 'relative',
                    fontSize: 12,
                    padding: '4px',
                    color: '#0b286b'
                }}>连续播放</span>
                <span style={{
                    width: '100px',
                    position: 'relative',
                    backgroundColor: '#fff',
                    border: '1px solid',
                    borderColor: '#8d939b',
                    marginLeft: '105px',
                    borderRadius: '3px',
                    fontSize: 12,
                    padding: '4px 14px',
                    color: '#0b286b'
                }} onClick={() => {
                    props.setIdx(1)
                }}>
                        返回三段法
                    </span>
            </div>

            <div className="video-point-group" style={{width: "96%", height: 300}}>
                <div className="point-button-container">
                    {pointButtons}
                </div>
            </div>


        </div>

    )
}

export default JumpDetailPage
