import MatchInfo from "../../data_structure/MatchInfo";
import {Select} from "antd";

import "./../pages/match/index.css"

const {Option} = Select;

export default function MatchTypeSelect(props) {
    return <Select
      showSearch
      style={{borderColor:'#0b286b'}}
      className="type-select"
      placeholder="比赛类型"
      optionFilterProp="children"
      {...props}
    >
        {MatchInfo.TYPE.map((item) => {
            return <Option key={item.key} value={item.key}>{item.label}</Option>;
        })}
    </Select>
}
