import React, {useContext, useEffect} from "react";
import './index.css'
import {store} from "../../../store";
import {Link} from "react-router-dom";


function DetailTable(props) {
    const {state,dispatch}=useContext(store)
    const swiper=props.swiper
    function toPercent(point){
        var percent = Number(point*100).toFixed(1);
        percent += "%";
        return percent;
    }
    function calCount(twoDArray){
        let count=0;
        if(twoDArray){
            for(let i=0;i<twoDArray.length;i++){
                if(twoDArray[i]){
                    count+=twoDArray[i].length;
                }
            }
        }
        return count;
    }
    function calScoreRate(player1or2,ColArray){
        let count_win = 0;
        for(let i=0;i<ColArray.length;i++){
            count_win += calCount(props.data[player1or2].win[ ColArray[i] ])
        }
        let count_whole = 0;
        for(let i=0;i<ColArray.length;i++){
            count_whole += calCount(props.data[player1or2].win[ ColArray[i] ]) + calCount(props.data[player1or2].lose[ ColArray[i] ])
        }
        return toPercent(count_win/count_whole)
    }
    function calUseRate(player1or2,ColArray){

        let count_whole_rally = 0;
        for(let i=0;i<6;i++){
            count_whole_rally += calCount(props.data[player1or2].win[i]) + calCount(props.data[player1or2].lose[ i])
        }
        let count_whole = 0;
        for(let i=0;i<ColArray.length;i++){
            count_whole += calCount(props.data[player1or2].win[ ColArray[i] ]) + calCount(props.data[player1or2].lose[ ColArray[i] ])
        }
        return toPercent(count_whole/count_whole_rally)
    }
    const tableMatrix=[{
        no:0,player: 0,row: [0,1],target:'player1'
    },{
        no:1,player: 1,row: [2,3],target:'player2'
    }]
    return (
        <div className="detail-table">

                {tableMatrix.map((tableItem)=>{
                    const basewin=props.data[tableItem.target]?props.data[tableItem.target].win:[]
                    const baselose=props.data[tableItem.target]?props.data[tableItem.target].lose:[]
                    let win_list=[],lose_list=[];
                    for(let i =0;i< basewin.length;i++){
                        win_list=win_list.concat(basewin[i])
                        lose_list=lose_list.concat(baselose[i])
                    }
                    return <div className={'detail-table-container-'+tableItem.no}>
                        <table>
                        <thead>
                        <tr>
                            <th className="name" rowSpan="2" colSpan="1" >
                                {props.players[tableItem.player]}
                            </th>
                            <th rowSpan="1" colSpan="2">发球抢攻段</th>
                            <th rowSpan="1" colSpan="2" >接发球抢攻段</th>
                            <th rowSpan="1" colSpan="2" >相持段</th>
                            <th style={{ width: "44px" }} rowSpan="2" colSpan="1" >合计</th>
                        </tr>
                        <tr className="second-head" >
                            <th>发球</th>
                            <th>第三拍</th>
                            <th>接发球</th>
                            <th>第四拍</th>
                            <th style={{ width: "38px" }}>发球轮相持</th>
                            <th style={{ width: "44px" }}>接发球轮相持</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th className="row-head">得分</th>
                            {props.data[tableItem.target]?props.data[tableItem.target].win.map((item, index) => {

                                return (<td key={index}
                                            onClick={()=>{dispatch({
                                                type:'setSelectDetail',
                                                payload:{
                                                    "m_id":props.match_id,
                                                    "table_idx":tableItem.no,
                                                    "col_idx":index,
                                                    "row_idx":0,
                                                    "list_content":item
                                                }
                                            });props.setIdx(3)}}
                                //             onClick={() => {
                                //     if (calCount(item) > 0) {
                                //         // setScoreData({ rallys: item.rallys, position, name: playerName, stage:stageMap[index]+'得分'})
                                //         // props.setIdx(3)
                                //     }
                                // }}
                                >{calCount(item)}</td>)
                            }): null}
                            {/*{props.data[tableItem.target]? <td key={6}*/}
                            {/*    onClick={()=>{dispatch({*/}
                            {/*        type:'setSelectDetail',*/}
                            {/*        payload:{*/}
                            {/*            "m_id":props.match_id,*/}
                            {/*            "table_idx":tableItem.no,*/}
                            {/*            "col_idx":6,*/}
                            {/*            "row_idx":0,*/}
                            {/*            "list_content":win_list*/}
                            {/*        }*/}
                            {/*    });props.setIdx(3)}}*/}
                            {/*>{calCount(win_list)}</td>:null}*/}
                        </tr>
                        <tr>
                            <th className="row-head">失分</th>
                            {props.data[tableItem.target]?props.data[tableItem.target].lose.map((item, index) => {
                                return (<td key={index} onClick={()=>{dispatch({
                                    type:'setSelectDetail',
                                    payload:{
                                        "m_id":props.match_id,
                                        "table_idx":tableItem.no,
                                        "col_idx":index,
                                        "row_idx":1,
                                        "list_content":item
                                    }
                                });props.setIdx(3)}} >{calCount(item)}</td>)
                            }): null}
                            {/*{props.data[tableItem.target]?<td key={6}*/}
                            {/*    onClick={()=>{dispatch({*/}
                            {/*        type:'setSelectDetail',*/}
                            {/*        payload:{*/}
                            {/*            "m_id":props.match_id,*/}
                            {/*            "table_idx":tableItem.no,*/}
                            {/*            "col_idx":7,*/}
                            {/*            "row_idx":0,*/}
                            {/*            "list_content":win_list*/}
                            {/*        }*/}
                            {/*    });props.setIdx(3)}}*/}
                            {/*>{calCount(lose_list)}</td>:null}*/}
                        </tr>
                        <tr>
                            <th className="row-head">得分率</th>
                            <td rowSpan="1" colSpan="2">
                                {props.data[tableItem.target]?
                                    calScoreRate(tableItem.target,[0,1])
                                    :null}
                            </td>
                            <td rowSpan="1" colSpan="2">
                                {props.data[tableItem.target]?calScoreRate(tableItem.target,[2,3]):null}
                            </td>
                            <td rowSpan="1" colSpan="2">
                                {props.data[tableItem.target]?calScoreRate(tableItem.target,[4,5]):null}
                            </td>
                            <td rowSpan="1" colSpan="1">{props.data[tableItem.target]?calScoreRate(tableItem.target,[0,1,2,3,4,5]):null}</td>

                        </tr>
                        <tr>
                            <th className="row-head">使用率</th>
                            <td rowSpan="1" colSpan="2">
                                {props.data[tableItem.target]?
                                    calUseRate(tableItem.target,[0,1]):null}
                            </td>
                            <td rowSpan="1" colSpan="2">
                                {props.data[tableItem.target]?calUseRate(tableItem.target,[2,3]):null}
                            </td>
                            <td rowSpan="1" colSpan="2">
                                {props.data[tableItem.target]?calUseRate(tableItem.target,[4,5]):null}
                            </td>
                            <td rowSpan="1" colSpan="1">{props.data[tableItem.target]?'100%':null}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                })}


        </div>
    )
}

export default DetailTable;

