import React from "react";
import {Button, Checkbox, Form, Input, message} from 'antd';
// import cookie from "react-cookies";
import {login_as_collector} from "../../../api/api";
export default function LoginToCollect(props) {
    function loginsuccess(uname,upwd){
        console.log('success')
        props.aftLogin(uname,upwd)
    }
    function loginfail(){
        console.log('fail')
        message.error('登录失败，请重试')
    }
    const onFinish = (values) => {

        login_as_collector(values.username,values.password).then(()=>loginsuccess(values.username,values.password),loginfail)
        props.closeModal()
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return <div>
        <Form
            name="basic"
            labelCol={{
                span: 6,
            }}
            wrapperCol={{
                span: 16,
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="用户名"
                name="username"
                rules={[
                    {
                        required: true,
                        message: 'Please input your username!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="密码"
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                ]}
            >
                <Input.Password />
            </Form.Item>


            <Form.Item
                wrapperCol={{
                    offset: 10,
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit">
                    登录
                </Button>
            </Form.Item>
        </Form>
    </div>
}