import React, {useState, useEffect, useRef, useContext, forwardRef} from "react";
import {Button, message, Radio} from 'antd'
import Swiper from 'react-id-swiper';
import VideoJS from "../../components/ShowVideo";
import './index.css'
import {store} from "../../../store";
import api from "../../../api/api";
import GameScorePage from "../GameScorePage";
import tableTennisIcon from "./../../../media/tt-white.png"
import MatchInfo from "../../../data_structure/MatchInfo";
import videojs from "video.js";
import TouchOverlay from './Dragtime';
import CustomDownloadButton from './Download';

const VideoPurePlay = (props) => {
    const {state,dispatch}= useContext(store);
    const match_id=state.match_id;
    const [isPure,setIsPure] = useState(!(state.matchInfo?.has_video))
    const [videoUrl,setVideoUrl] = useState(isPure?api.getPureVideoUrl(match_id):api.getVideoUrl(match_id))
    const players=state.matchInfo.players;
    const playerRef = useRef(null);
    const [currentTime, setCurrentTime] = useState(0)
    const [pauseTime, setPauseTime] = useState(0)
    const [ifPause, setIfPause] = useState(true)
    const Component = videojs.getComponent("Component");

    useEffect(() => {
        if (props.activeIndex === 0) {
            setIfPause(false)
        } else {
            setIfPause(true)
        }
    },[props.activeIndex])

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        bigPlayButton: true,
        responsive: true,
        fluid: true,
        preload: 'auto',
        language: 'zh-CN', // 设置语言
        controlBar: {
            children: [
                { name: 'playToggle' },
                { name: 'currentTimeDisplay' }, // 当前已播放时间
                { name: 'progressControl' },
                { name: 'durationDisplay' }, // 总时间
                {
                    name: 'volumePanel', // 音量控制
                    inline:false
                },
                { name: 'CustomDownloadButton' },
                { name: 'FullscreenToggle' }, // 全屏
            ],
        },
        sources: [{
            src: videoUrl,
            type: 'video/mp4'
        }]
    }


    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // you can handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });

        Component.registerComponent("TouchOverlay", TouchOverlay);
        const touchOptions = {};
        const properIndex = player.children().indexOf(player.getChild("BigPlayButton"));
        player.addChild("TouchOverlay", touchOptions, properIndex);
        player.trigger('filenameSet', getFileName());
    };
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = videoUrl;
        // link.target = "_blank"
        link.download = getFileName() || 'defaultFileName'; // 设置下载文件名
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const getFileName = () => {
        return  `${state.matchInfo.date} `
        + `${state.matchInfo.location}${MatchInfo.TYPE.find(t => t.key === state.matchInfo.type) ? ` ${MatchInfo.TYPE.find(t => t.key === state.matchInfo.type).label}` : ''}`
        + `${MatchInfo.COMPETITION.find(t => t.key === state.matchInfo.competition) ? ` ${MatchInfo.COMPETITION.find(t => t.key === state.matchInfo.competition).label}` : ''}`
        + `${MatchInfo.ROUND.find(t => t.key === state.matchInfo.round) ? ` ${MatchInfo.ROUND.find(t => t.key === state.matchInfo.round).label}` : ''}`
        + ` ${(players[0].length > 1 ? players[0][0] + players[0][1] : players[0][0])}`
        + `vs${(players[1].length > 1 ? players[1][0] + players[1][1] : players[1][0])}`
        + `${isPure ? '（整场纯净版）.mp4' : '-video.mp4'}`;
    }
    return (
        <div className="mobile-page">

            <div className="mobile-subtitle">
                <img src={tableTennisIcon} width={26} style={{position: 'absolute', left: 2, top: 2}}/>
                <div>全场视频及比分</div>
            </div>
            <div>
                <div>
                    {state.matchInfo.date.slice(0, 4) + '-' + state.matchInfo.date.slice(4, 6) + '-' + state.matchInfo.date.slice(6, 8) + ' ' + state.matchInfo.location}
                </div>
                <span style={{height: '28px', verticalAlign: 'center'}}>
                                          {MatchInfo.TYPE.find(t => t.key === state.matchInfo.type) ? MatchInfo.TYPE.find(t => t.key === state.matchInfo.type).label : null}
                    {" "}
                    {MatchInfo.COMPETITION.find(t => t.key === state.matchInfo.competition) ? MatchInfo.COMPETITION.find(t => t.key === state.matchInfo.competition).label : null}
                    {MatchInfo.ROUND.find(t => t.key === state.matchInfo.round) ? MatchInfo.ROUND.find(t => t.key === state.matchInfo.round).label : null}
                    {isPure ? " （纯净版）" : ""} </span>
            </div>
            <div className="video-container">
                {state.matchInfo.has_video || state.matchInfo.has_purevideo?
                    <div className="video-player">
                        <VideoJS
                            options={videoJsOptions}
                            onReady={handlePlayerReady}
                            currentTime={currentTime}
                            pauseTime={pauseTime}
                            key={videoUrl}
                            ifPause={ifPause} isFull={true}/>
                        <div style={{marginTop:5}}>
                            <Button onClick={handleDownload} style={{marginRight:20}} >全屏播放</Button>
                            <Button onClick={()=>{
                                if (!(isPure && state.matchInfo.has_video || !isPure && state.matchInfo.has_purevideo)) {
                                    message.warn(`暂无${isPure?'完整版':'纯净版'}视频`)
                                return
                                }
                                let origin_is_pure = isPure
                                setIsPure(!isPure);
                                setVideoUrl(
                                    !origin_is_pure?
                                        api.getPureVideoUrl(match_id)
                                        :api.getVideoUrl(match_id))
                            }}
                            style={{cursor:(isPure && state.matchInfo.has_video || !isPure && state.matchInfo.has_purevideo)?'pointer':'not-allowed'}}
                            >{isPure?'切换完整版':'切换纯净版'}</Button>
                        </div>

                    </div> : null}
                <div className="video-banner">
                    <div className="banner-player">
                        <span className="player-name">
                            {(players[0]).length > 1 ? <div>
                                    <span>{players[0][0]}</span><span>{" "}</span>
                                    <span>{players[0][1]}</span></div>
                                : players[0]
                            }
                        </span>
                        <span className="vs">vs</span>
                        <span className="player-name">
                            {(players[1]).length > 1 ? <div>
                                    <span>{players[1][0]}</span><span>{" "}</span>
                                    <span>{players[1][1]}</span></div>
                                : players[1]
                            }
                        </span>
                    </div>
                    <div className={'video-divider'}>
                        <div className={'vs-divider'} style={{backgroundColor: '#587ec4', marginBottom: 5}}></div>
                        <div
                            className={'vs-result'}>{state.matchInfo.has_data && state.gameResult[0] !== null ? state.gameResult[0] + '-' + state.gameResult[1] : null}</div>
                        <div className={'vs-divider'} style={{backgroundColor: '#fbb03b', marginBottom: 5}}></div>
                    </div>
                </div>
            </div>

            {state.matchInfo.has_data ?
                <GameScorePage/>
                : null}
            <div className="progress-container">
                <progress id="downloadProgress" value="0" max="100"></progress>
                <span id="progressText">下载进度: 0%</span>
            </div>
        </div>
    )
}

export default VideoPurePlay
