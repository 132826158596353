import MatchInfo from "../data_structure/MatchInfo";
import errorInfo from "./errorInfo";
import onProgress from "../ui/pages/match/progress";
// import cookie from "react-cookies"
import axios from "axios";
import {useContext} from "react";
import {store} from "../store";
import {DEBUG,LOCAL,nodeList} from './config'
export const AUTO_TEST = false;

let token = null;
let headers = new Headers();
// let isLoggingIn = false;
let isLoggingInAsCollector = false;
const useLocal = false
const useOly = false
//region utils
const backend_url = useLocal?"http://127.0.0.1:5000":useOly?"https://olyttbackend.sportvis.cn":"https://ttbackend.sportvis.cn"


class BaseApi {
    constructor() {
        this._nodeList = Object.fromEntries(
            Object.keys(nodeList)
                .map(n => [n, {
                    label: nodeList[n].label,
                    address: nodeList[n].address,
                    lag: -1,
                    ip:nodeList[n].ip
                }])
        );
        this.isLoggingIn = false
        this.nodeIP = '';
        Promise.race(Object.keys(nodeList).map(node =>
            this.speedTest(node)
                .then(lag => {
                    console.log(`Node ${node} responses in ${lag} ms.`);
                    this._nodeList[node].lag = lag;
                    return node;
                })
        )).then(node => {

            if (!this._nodeList[node]) return;
            this.nodeIP = this._nodeList[node].ip;
        });

    }
    getIP(){
        return this.nodeIP
    }
    speedTest = (node) => {
        const t = new Date().getTime();
        return fetch(nodeList[node].address)
            .then(() => new Date().getTime() - t);
    }
    // url = (uri,ip, params = null) =>
    //     `http://${LOCAL ? "10.76.2.57" : ip}:5000${uri}${params === null ? "" : "?" + new URLSearchParams(params)}`;
    url = (uri,ip, params = null) =>
        `${backend_url}${uri}${params === null ? "" : "?" + new URLSearchParams(params)}`;
    // url_ip = (uri,ip, params = null) =>
    //     `http://119.45.201.191:5000${uri}${params === null ? "" : "?" + new URLSearchParams(params)}`;


    custom_fetch(uri,params, init, resType = 'json') {

        return new Promise((resolve, reject) => {
            const itv = setInterval(() => {

                if (this.nodeIP==='' || !this.nodeIP) return;
                clearInterval(itv);
                const req = fetch(
                    `${LOCAL ? "http://localhost:5000":backend_url}${uri}`,
                    Object.assign({}, init,{headers})
                );

                if (resType === 'json')
                    resolve(req);
                else
                    resolve(req);
            }, 100);
        });
    }


    login = () => new Promise((resolve, reject) => {


        if (this.isLoggingIn) {
            const interval = setInterval(() => {
                if (this.isLoggingIn) return;
                if (token === null || token === undefined) reject(new Error("未能成功登录"));
                else resolve();
                clearInterval(interval);
            }, 100);
        } else {
            this.isLoggingIn = true;
            this.custom_fetch("/v1/usr/login",null, {
                method: "POST",
                body: JSON.stringify({
                    username: '2',
                    password: '123456',
                })
            }).then(res => res.json())
                .then(res => {
                    if (!res.succeed) {
                        reject(new Error("未能成功登录"));
                    } else {
                        token=res.token
                        headers.set("Token", res.token);


                        resolve();
                    }
                    this.isLoggingIn = false;
                })
        }
    });
    request = (input, init, params) => this.login().then(() => this.custom_fetch(input, params, init))
}
let baseApi = new BaseApi()



const login_as_collector = (username,password) => new Promise((resolve, reject) => {

    if (isLoggingInAsCollector) {
        const interval = setInterval(() => {
            if (isLoggingInAsCollector) return;
            if (token === null || token === undefined) reject(new Error("未能成功登录"));
            else resolve();
            clearInterval(interval);
        }, 100);
    } else {
        isLoggingInAsCollector = true;
        fetch(baseApi.url("/v1/usr/login",baseApi.getIP()), {
            method: "POST",
            body: JSON.stringify({
                username: username,
                password: password,
            })
        }).then(res => res.json())
            .then(res => {
                if (!res.succeed) {
                    reject(new Error("未能成功登录"));
                } else {
                    token=res.token
                    headers.set("Token", res.token);
                    resolve();
                }
                isLoggingInAsCollector = false;
            })
    }
});
const requestWithProgress = (method, uri, form,name,pwd, onProgress) => login_as_collector(name,pwd).then(() => new Promise((resolve, reject) => {
    let oldLoaded, oldTime;

    const xhr = new XMLHttpRequest();
    xhr.open(method, baseApi.url(uri,baseApi.getIP()), true);
    xhr.setRequestHeader("Token", token);
    xhr.onload = () => {
        onProgress(100, 0, 0);
        resolve(JSON.parse(xhr.response));
    }
    xhr.onerror = e => {
        reject(e);
        console.error(e);
    }
    xhr.upload.onprogress = e => {
        const currentLoaded = e.loaded, currentTime = new Date().getTime();
        const difLoaded = currentLoaded - oldLoaded, difTime = (currentTime - oldTime) / 1000;

        let speed = difLoaded / difTime;
        let leftTime = (e.total - e.loaded) / speed;

        if (onProgress) onProgress(e.loaded / e.total * 100, speed, leftTime);
    };
    xhr.onloadstart = () => {
        oldTime = new Date().getTime();
        oldLoaded = 0;
    };
    xhr.send(form);
}));

// const request = (input, init, params) => login().then(() => fetch(baseApi.url(input,baseApi.getIP(), params), Object.assign({}, init, {headers})));
const NOT_SHARED = -2
const share_match_id=window.location.href.split('/').reverse()[1]==='game'?parseInt(window.location.href.split('/').reverse()[0]):NOT_SHARED;//-2 means not shared
//endregion

const api = {

    //region test
    test: () => {
        api.getMatchList_i()
          .then(players => console.log(players))
          .catch(console.error);
    },
    //endregion

    //region matches
    /**
     * @callback onUploadDataProgress
     * @param {string} message: status text
     * @param {number} progress: 0~100
     */
    /**
     * @param {MatchInfo} matchInfo
     * @param {File} videoFile
     * @param pureVideoFile
     * @param {File} dataFile
     * @param collectorInfoName
     * @param collectorInfoPwd
     * @param {onUploadDataProgress} onProgress
     * need auth
     */
    newMatch: (matchInfo, videoFile = null,pureVideoFile=null, dataFile = null, collectorInfoName = '',collectorInfoPwd = '',onProgress = null) => {
        console.log("api.newMatch:", matchInfo, videoFile,pureVideoFile, dataFile);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}));

        const form = new FormData();
        form.append("matchInfo", matchInfo.str());
        if (videoFile !== null)
            form.append("videoFile", videoFile);
        if (dataFile !== null)
            form.append("dataFile", dataFile);
        if (pureVideoFile !== null)
            form.append("pureVideoFile", pureVideoFile);

        return requestWithProgress(
          "put",
          "/v1/match_collector",
          form,
          collectorInfoName, collectorInfoPwd,
          progress => onProgress("Create match...", progress)
        );
    },

    /**
     */
    getMatchList_i: (start = null, end = null) => {

        const params = (start === null && end === null) ? null : {};
        if (start !== null) params.start = start;
        if (end !== null) params.end = end;
        console.log("api.getmatchlist_i")
        return baseApi.request("/v1/matchesid", null,params)
            .then(res => res.json())
            .then(res => new Promise((resolve, reject) => {
                if (res.succeed) resolve(res.info);
                else reject(res.info);
            }));
    },

    /**
     * @param {number} match_id: match id
     * @param {string} data_type: type of data
     */
    // score multi 3stage 4stage
    getScoreInfo: (match_id, data_type= '' +
    '' +
    '') => {
        console.log("api.getScoreInfo specific",match_id,data_type);

        return baseApi.request(`/v1/data/${match_id}/${data_type}`, null)
            .then(res => res.json())
            .then(res => new Promise((resolve, reject) => {
                if (res.succeed) resolve(res.data);
                else reject(res.info);
            }));
        // 'succeed':True,'status','players','data'
    },

    /**
     * @param {number} match_id: match id
     */
    // score multi 3stage 4stage
    getScoreDetail: (match_id) => {
        console.log("api.getScoreDetail",match_id);

        return baseApi.request(`/v1/match/${match_id}`, null)
            .then(res => res.json())
            .then(res => new Promise((resolve, reject) => {
                if (res.succeed) resolve(res.data);
                else reject(res.info);
            }));
    },
    getPlayerNameDictList:()=>{
        return baseApi.request(`/v1/player_name_dict`, null)
            .then(res => res.json())
            .then(res => new Promise((resolve, reject) => {
                if (res.succeed) resolve(res.data);
                else reject(res.info);
            }));
    },
    /**
     * @param {number} match_id: match id
     */
    getVideo: (match_id) => {
        console.log("api.getVideo",match_id);

        return baseApi.request(`/v1/video/${match_id}/v.mp4`, null)
            // .then(res => new Promise((resolve, reject) => {
            //     if (res.succeed) resolve(res);
            //     else reject(res.info);
            // }));
    },
    /**
     * @param {number} match_id: match id
     */
    getVideoUrl: (match_id) => {
        if(match_id>0){
            return `${backend_url}/v1/video/${match_id}/v.mp4`
        }else{
            return `${backend_url}/v1/video/${share_match_id}/v.mp4`
        }
    },
    getPureVideoUrl: (match_id)=>{
        if(match_id>0){
            return `${backend_url}/v1/purevideo/${match_id}/purev.mp4`
        }else{
            return `${backend_url}/v1/purevideo/${share_match_id}/purev.mp4`
        }
    },
    //endregion

    /**
     * @param {number|null} start: the start index (null for no limit)
     * @param {number|null} end: the end index (null for no limit)
     */
    getPlayerList: (start = null, end = null) => {


        const params = (start === null && end === null) ? null : {};
        if (start !== null) params.start = start;
        if (end !== null) params.end = end;
        return baseApi.request("/v1/players", null, params)
            .then(res => res.json())
            .then(res => new Promise((resolve, reject) => {
                if (res.succeed) resolve(res.info);
                else reject(res.info);
            }));
    },
}

export default api;
export {
    errorInfo,login_as_collector
};
