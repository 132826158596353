import React, {useState, useRef, useContext, useEffect} from "react";
import Swiper from "react-id-swiper"
import ReactResizeDetector from 'react-resize-detector';
import leftArrow from "../../../media/leftarrow.png"
import rightArrow from "../../../media/rightarrow.png"
import GameScorePage from "../GameScorePage/index"
import RallyInfoPage from "../RallyInfoPage"
import VideoPage from "../VideoPage/VideoPurePlay"
import StageAnalysisPage from "../StageAnalysisPage/index"
import JumpDetailPage from "../JumpDetail/index"
import 'swiper/swiper-bundle.css'
import 'swiper/swiper.min.css'

import SwiperCore, {Keyboard, Mousewheel} from "swiper";
import {store} from "../../../store";
import api, {errorInfo} from "../../../api/api";
import cookie from "react-cookies";
import MatchInfo from "../../../data_structure/MatchInfo";
import {Button, message} from "antd";
import * as path from "path";
import MyHeader from "../../components/MyHeader";
import MyTail from "../../components/MyTail";
import MyLoading from '../../components/MyLoading'
SwiperCore.use([Keyboard, Mousewheel]);

let mobileClientWidth = 0


function MobileView(props) {
    const {state, dispatch} = useContext(store)
    const [activeIndex, setActiveIndex] = useState(0)
    const swiperRef = useRef(null)
    const [swiper, setSwiper] = useState(null);
    const pathname = props.location.pathname;
    const match_id = props.match.params.id;
    // Params definition
    const params = {
        // direction: 'vertical',
        slidesPerView: 1,
        spaceBetween: 30,
        // threshold:60,
        mousewheel: true,
        simulateTouch: false,
        allowTouchMove:false,
        // allowSlideNext:false,
        // allowSlidePrev:true,
        height: document.body.clientHeight,
        width: document.body.clientWidth,
        on: {
            'slideChange': () => {
                setActiveIndex(swiperRef.current.swiper.activeIndex)
            }
        }
    };
    const getDetailMatchInfo = async (match_id) => {
        try {
            const result = await api.getMatchList_i()
            for (let i = 0; i < result.length; i++) {
                if (result[i].m_id === match_id) {
                    dispatch({
                        type: 'setMatchInfoById',
                        payload: {matchInfo: new MatchInfo(result[i]), match_id: match_id}
                    })
                }
            }
        } catch (e) {
            message.error(errorInfo(e))
        }
    };
    useEffect(() => {
        console.log(state.match_id)
        if (state.match_id === -1 ) {
            console.log("mobile home,m_id=",props.match.params.id,state.match_id)
            cookie.save('match_id', props.match.params.id, {path: '/'})
            getDetailMatchInfo(props.match.params.id)
        }
    })

    const onResize = (handleWidth, handleHeight) => {
        mobileClientWidth = handleWidth
    }
    const goNext = () => {
        // if (swiper !== null) {
        //     swiper.slideNext();
        // }
        console.log('next',(activeIndex+1)%4)
        setActiveIndex((activeIndex+1)%4)
        // swiperRef.current.swiper.slideNext()
    };

    const goPrev = () => {
        // if (swiper !== null) {
        //     swiper.slidePrev();
        // }
        // swiperRef.current.swiper.slidePrev()
        setActiveIndex((activeIndex-1)%4)
    };

    return (
        <div id='mobile-swiper'
             style={{position: 'relative', minHeight:document.documentElement.clientHeight}}
             // style={{overflowY: 'hidden'
            // ,height: document.body.clientHeight
        // }}
        >

            <MyHeader/>
            <div style={{position:'fixed',left:10,top:70,zIndex:120}}>
                <img style={{position:'fixed'}} src={leftArrow} width={20} onClick={goPrev}/>
            </div>
            <div style={{position:'fixed',right:10,top:70,zIndex:120}}>
                <img src={rightArrow} width={20} onClick={goNext}/>
            </div>
            {/*<button style={{position:'absolute',left:0,top:20}} onClick={goPrev}>Prev</button>*/}
            {/*<button style={{position:'absolute',right:0,top:20}} onClick={goNext}>Next</button>*/}
            <div className="swiper" style={{minHeight:document.documentElement.clientHeight-52-30}}>
                {/* <ReactResizeDetector handleWidth handleHeight onResize={onResize}/> */}
                <Swiper ref={swiperRef}></Swiper>
                {state.matchInfo.date ? (state.matchInfo.has_data ?

                    activeIndex === 0 ?
                        <div className="mobile-slide-item"
                            //  style={{width: params.width, marginBottom: 30}}
                        >
                            <VideoPage activeIndex={activeIndex} swiper={swiperRef} pathname={pathname}/>
                        </div>
                        :
                        activeIndex === 1 ?
                            <div className="mobile-slide-item"
                                // style={{width: params.width, marginBottom: 30}}
                            >
                                <StageAnalysisPage activeIndex={activeIndex} swiper={swiperRef} setIdx={setActiveIndex}
                                                   pathname={pathname}/>
                            </div>
                            : activeIndex === 2 ?
                                <div className="mobile-slide-item"
                                    // style={{width: params.width, marginBottom: 30}}
                                >
                                    <RallyInfoPage activeIndex={activeIndex} swiper={swiperRef} pathname={pathname}/>
                                </div>
                                :
                                <div className="mobile-slide-item"
                                    // style={{width: params.width, marginBottom: 30}}
                                >
                                    <JumpDetailPage activeIndex={activeIndex} swiper={swiperRef} setIdx={setActiveIndex}
                                                    pathname={pathname}/>
                                </div>

                    : <div className="mobile-slide-item"
                        // style={{width: params.width, marginBottom: 30}}
                    >
                        <VideoPage activeIndex={activeIndex} swiper={swiperRef} pathname={pathname}/>
                    </div>) : <MyLoading/>}
                {/*{state.matchInfo.has_data ?*/}
                {/*    <Swiper {...params}*/}
                {/*            ref={swiperRef}>*/}
                {/*        /!*activeIndex===0?*!/*/}
                {/*        <div className="mobile-slide-item"*/}
                {/*             style={{width: params.width, marginBottom: 30}}*/}
                {/*        >*/}
                {/*            <VideoPage activeIndex={activeIndex} swiper={swiperRef} pathname={pathname}/>*/}
                {/*        </div>*/}
                {/*        /!*:*!/*/}
                {/*        /!*activeIndex===1?*!/*/}
                {/*        <div className="mobile-slide-item" style={{width: params.width, marginBottom: 30}}>*/}
                {/*            <StageAnalysisPage activeIndex={activeIndex} swiper={swiperRef} pathname={pathname}/>*/}
                {/*        </div>*/}
                {/*        /!*:activeIndex===2?*!/*/}
                {/*        <div className="mobile-slide-item" style={{width: params.width, marginBottom: 30}}>*/}
                {/*            <RallyInfoPage activeIndex={activeIndex} swiper={swiperRef} pathname={pathname}/>*/}
                {/*        </div>*/}
                {/*        /!*:*!/*/}
                {/*        <div className="mobile-slide-item" style={{width: params.width, marginBottom: 30}}>*/}
                {/*            <JumpDetailPage activeIndex={activeIndex} swiper={swiperRef} pathname={pathname}/>*/}
                {/*        </div>*/}

                {/*    </Swiper>*/}
                {/*    : <div className="mobile-slide-item" style={{width: params.width, marginBottom: 30}} >*/}
                {/*        <VideoPage activeIndex={activeIndex} swiper={swiperRef} pathname={pathname}/>*/}
                {/*    </div>}*/}

            </div>
            <MyTail />
        </div>
    );
}

export default MobileView
