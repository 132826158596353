import "antd/dist/antd.css";
import AddMatch from "./match/add";
import LoginToCollect from "../components/user/LoginToCollect";
import {
  HashRouter as Router,
  Switch,
  Route,
  //useParams
} from "react-router-dom";
import Homepage from "./HomePage/HomePage";
import MobileView from "./Mobile";
export default function RouterComm() {
  return <Router>
    <Switch>
      <Route exact path="/" component={Homepage}/>
      <Route exact path="/game/:id" component={MobileView}/>
      {/*<Route exact path='/add'  component={AddMatch} />*/}
      <Route exact path='/login'  component={LoginToCollect} />
    </Switch>
  </Router>;
}
