let last = -1;

const randomInt = max => Math.floor(Math.random() * max);

const randomFromArray = (arr, allowContinuousSameChoice = true) => {
    let randomIndex = randomInt(arr.length);
    if (!allowContinuousSameChoice)
        while (randomIndex === last) {
            randomIndex = randomInt(arr.length);
        }

    last = randomIndex;
    return arr[last];
};

export default randomFromArray;
